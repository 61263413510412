export class Constants {
  static defaultLimit = 50;

  static cronoAdminSessionCookie = "cronoAdminSession";
  static maxMonthScrapeRequest = 1000;
  static maxMonthVerifyRequest = 10000;
  static maxMonthGenerateTemplateRequest = 1000;

  static defaultImageUrl =
    "https://crono-assets.s3.eu-central-1.amazonaws.com/defaultCompanyLogo.png";

  static websiteRegex =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

  static linkedinUrlRegex =
    /https:\/\/www\.linkedin\.com\/company\/[a-zA-Z0-9-]+\/?/;

  static domainRegex =
    /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/;

  static pricesDev = [
    {
      priceId: "price_1OXLu7ErlfuRmsVB5VeoLvoR",
      label: "Ultra monthly",
    },
    {
      priceId: "price_1OXLs9ErlfuRmsVBhZFvFxMg",
      label: "Pro monthly",
    },
  ];

  static pricesPro = [
    {
      priceId: "price_1OhqvjErlfuRmsVByCsXlirN",
      label: "Ultra monthly",
    },
    {
      priceId: "price_1OhqwWErlfuRmsVBehp8tNtZ",
      label: "Pro monthly",
    },
    {
      priceId: "price_1OhqweErlfuRmsVBcQvudg4p",
      label: "Starter monthly",
    },
    {
      priceId: "price_1OhqvjErlfuRmsVBuyc0WQJ4",
      label: "Ultra yearly",
    },
    {
      priceId: "price_1OhqwWErlfuRmsVB0EJDARYY",
      label: "Pro yearly",
    },
    {
      priceId: "price_1OhqweErlfuRmsVBJ8MG1l5x",
      label: "Starter yearly",
    },
  ];
}
