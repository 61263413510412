import { Grid, Tooltip } from "@mui/material";
import { FlexDiv } from "components/Layout/FlexDiv";
import { FC, useEffect, useState } from "react";
import { SubscriptionFilterWrapper } from "./styles";
import { SubscriptionFilterProps } from "./types";
import SubscriptionType from "types/enums/subscriptionType";
import { set } from "date-fns";

const SubscriptionFilter: FC<SubscriptionFilterProps> = ({
  onFiltersChange,
}) => {
  const [activeSelected, setActiveSelected] = useState(false);
  const [onlyFreeTrialSelected, setOnlyFreeTrialSelected] = useState(false);
  const [sortByExpirationDate, setSortByExpirationDate] = useState(false);
  const [sortByLastLogin, setSortByLastLogin] = useState(false);

  useEffect(() => {
    let filters: any = {};

    if (activeSelected) {
      filters.active = activeSelected;
    }

    if (onlyFreeTrialSelected) {
      filters.type = SubscriptionType.FREE_TRIAL;
    }

    if (sortByExpirationDate) {
      filters.sortByExpirationDate = sortByExpirationDate;
      filters.sortByLastLogin = false;
    }

    if (sortByLastLogin) {
      filters.sortByLastLogin = sortByLastLogin;
      filters.sortByExpirationDate = false;
    }

    onFiltersChange(filters);
  }, [
    activeSelected,
    onlyFreeTrialSelected,
    sortByExpirationDate,
    sortByLastLogin,
  ]);

  return (
    <SubscriptionFilterWrapper>
      <Grid container spacing={0} rowGap={1}>
        <Grid item xs={12} sm={12} md={3} lg={3}></Grid>
        <Grid item xs={12} sm={12} md={9} lg={9}>
          <Grid container spacing={0} rowGap={1}>
            <Grid item xs={4} sm={4} md={4} lg={2}>
              <FlexDiv className="btn-container">
                <Tooltip title="">
                  <div className={`inactive status-btn`}>Integration</div>
                </Tooltip>
              </FlexDiv>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={2}>
              <FlexDiv className="btn-container">
                <Tooltip title="">
                  <div
                    className={`nurture status-btn ${
                      sortByExpirationDate ? "nurture-selected" : ""
                    }`}
                    onClick={() => {
                      setSortByExpirationDate((prev) => !prev);
                      setSortByLastLogin(false);
                    }}
                  >
                    Expiration
                  </div>
                </Tooltip>
              </FlexDiv>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={2}>
              <FlexDiv className="btn-container">
                <Tooltip title="">
                  <div className={`working status-btn`}>Max users</div>
                </Tooltip>
              </FlexDiv>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={2}>
              <FlexDiv className="btn-container">
                <Tooltip title="">
                  <div
                    className={`customer status-btn ${
                      sortByLastLogin ? "customer-selected" : ""
                    }`}
                    onClick={() => {
                      setSortByLastLogin((prev) => !prev);
                      setSortByExpirationDate(false);
                    }}
                  >
                    Last Login
                  </div>
                </Tooltip>
              </FlexDiv>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={2}>
              <FlexDiv className="btn-container">
                <Tooltip title="">
                  <div
                    className={`call-scheduled status-btn ${
                      onlyFreeTrialSelected ? "call-scheduled-selected" : ""
                    }`}
                    onClick={() => setOnlyFreeTrialSelected((prev) => !prev)}
                  >
                    Subscription Type
                  </div>
                </Tooltip>
              </FlexDiv>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={2}>
              <FlexDiv className="btn-container">
                <Tooltip title="">
                  <div
                    className={`working status-btn ${
                      activeSelected ? "working-selected" : ""
                    }`}
                    onClick={() => setActiveSelected((prev) => !prev)}
                  >
                    Active
                  </div>
                </Tooltip>
              </FlexDiv>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SubscriptionFilterWrapper>
  );
};

export default SubscriptionFilter;
