import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/clients/client";
import { Request } from "types/request";
import { Response } from "types/response";

export interface UserPatch {
  subscriptionId: number;
  userId: number;
  manager?: boolean;
  subscriptionManager?: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
}

export default function usePatchUser() {
  const queryClient = useQueryClient();

  const patchUser = (userPatch: UserPatch) => {
    const request: Request = {
      url: `${ENDPOINTS.user.main}`,
      config: {
        method: "patch",
        data: { data: userPatch },
      },
    };
    return client(request);
  };

  return useMutation<Response<null>, Error, UserPatch>(patchUser, {
    onSettled: (result, error, variables) => {
      queryClient.invalidateQueries([ENDPOINTS.user.main]);
      queryClient.invalidateQueries([ENDPOINTS.subscription.users]);
      queryClient.invalidateQueries([
        ENDPOINTS.subscription.main,
        variables.subscriptionId,
      ]);
      queryClient.invalidateQueries([
        ENDPOINTS.subscription.users,
        variables.subscriptionId,
      ]);
    },
  });
}
