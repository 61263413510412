import * as Yup from "yup";

export const validationSchema = Yup.object({
  oldPassword: Yup.string().min(4, "Password must be at least 4 characters long").required("Old password is required"),
  newPassword: Yup.string().min(4, "Password must be at least 4 characters long").required("New password is required"),
  confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], "Passwords don't match.").required("Confirm password is required")
});

export interface PasswordInputs {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const initialValues = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};
