import { FC, useEffect, useMemo, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useSelectedSubscription } from "../context/subscription";
import { FlexDiv } from "components/Layout/FlexDiv";
import {
  SubscriptionInfoWrapper,
  ActiveSwitch,
  AddCreditWrapper,
} from "./styles";
import { ChipInput } from "components/ChipInput";

import moment from "moment";
import { SubscriptionInfoInputs } from "./model";
import useEditSubscription from "hooks/services/subscription/useEditSubscription";
import { useConfirmModal } from "context/confirmModal";
import { useConditionalSnackBar } from "context/snackbar";
import { checkIfValidWebsite, getError } from "utils";
import SubscriptionType from "types/enums/subscriptionType";
import { useAuth } from "context/auth";
import {
  Add,
  Close,
  Delete,
  EditRounded,
  OpenInNewRounded,
} from "@mui/icons-material";
import useDeleteSubscription from "hooks/services/subscription/useDeleteSubscription";
import EmailIntegrationType from "types/enums/emailIntegrationType";
import { createPortal } from "react-dom";
import usePostSubscriptionBucket, {
  CreditsType,
} from "hooks/services/subscription/usePostSubscriptionBucket";
import EditDomainsModal from "./editDomainsModal";

const SubscriptionInfo: FC = () => {
  const { admin } = useAuth();
  const { subscription } = useSelectedSubscription();

  const [name, setName] = useState<string>("");
  const [companyWebsite, setCompanyWebsite] = useState<string | null>(null);
  const [companyLinkedinUrl, setCompanyLinkedinUrl] = useState<string | null>(
    null
  );
  const [active, setActive] = useState<boolean>(false);
  const [integrationType, setIntegrationType] = useState<string>("");
  const [emailIntegrationType, setEmailIntegrationType] = useState<string>("");
  const [callIntegrationType, setCallIntegrationType] = useState<string>("");
  const [subscriptionType, setSubscriptionType] = useState<string>("");
  const [expirationDate, setExpirationDate] = useState<Date | null>(null);
  const [creditsRenewalDate, setCreditsRenewalDate] = useState<Date | null>(
    null
  );
  const [basicUserQuantity, setBasicUserQuantity] = useState<number>(0);
  const [managerUserQuantity, setManagerUserQuantity] = useState<number>(0);
  const [expirationDatePickerOpen, setExpirationDatePickerOpen] =
    useState<boolean>(false);
  const [creditsRenewalDatePickerOpen, setCreditsRenewalDatePickerOpen] =
    useState<boolean>(false);

  const [selectedCreditTypeToAdd, setSelectedCreditTypeToAdd] =
    useState<CreditsType | null>(null);
  const [numberOfCredits, setNumberOfCredits] = useState<string>("10");
  const [subscriptionDomains, setSubscriptionDomains] = useState<
    string[] | null
  >(null);
  const [priceId, setPriceId] = useState<string | null>(null);
  const [coupon, setCoupon] = useState<string | null>(null);

  //Edit domains for multicustomer subscription
  const [showDomainsModal, setShowDomainsModal] = useState<boolean>(false);

  const nanValueDetected = useMemo(() => {
    return isNaN(Number(numberOfCredits)) || !numberOfCredits;
  }, [numberOfCredits]);
  useEffect(() => {
    setName(subscription?.company.name || "");
    setCompanyWebsite(subscription?.company.website || null);
    setCompanyLinkedinUrl(subscription?.company.linkedinUrl || null);
    setActive(subscription?.active || false);
    setIntegrationType(subscription?.company.integrationType || "");
    setSubscriptionType(subscription?.subscriptionType || "");
    setEmailIntegrationType(subscription?.company.emailIntegrationType || "");
    setCallIntegrationType(subscription?.company.callIntegrationType || "");
    setExpirationDate(moment(subscription?.expirationDate).toDate() || null);
    setCreditsRenewalDate(
      subscription?.creditsRenewalDate
        ? moment(subscription.creditsRenewalDate).toDate()
        : null
    );
    setBasicUserQuantity(subscription?.enabledUsers.Basic || 0);
    setManagerUserQuantity(subscription?.enabledUsers.Manager || 0);
    setSubscriptionDomains(subscription?.subscriptionDomains || null);
    setPriceId(subscription?.priceId || null);
    setCoupon(subscription?.coupon || null);
  }, [subscription]);

  const {
    mutate: editSubscription,
    data: editData,
    error: editError,
    isSuccess: isSuccessEdit,
  } = useEditSubscription();

  useEffect(() => {
    if (isSuccessEdit) {
      setShowDomainsModal(false);
    }
  }, [isSuccessEdit]);

  const {
    mutate: deleteSubscription,
    data: isDeleteSuccess,
    error: isDeleteError,
    isLoading: isDeleteLoading,
  } = useDeleteSubscription();

  const {
    mutate: postSubscriptionBucket,
    isLoading: isLoadingPostSubscriptionBucket,
    error: errorPostSubscriptionBucket,
    isSuccess: isSuccessPostSubscriptionBucket,
  } = usePostSubscriptionBucket();

  useEffect(() => {
    setSelectedCreditTypeToAdd(null);
  }, [isSuccessPostSubscriptionBucket]);

  const [error, setError] = useState<string | null>(null);

  const { openModal: openConfirmModal } = useConfirmModal();

  useConditionalSnackBar([
    {
      condition: !!editError,
      message: getError(editError) ?? "Error while update the subscription",
      severity: "error",
    },
    {
      condition: !!error,
      message: error!,
      severity: "error",
    },
    {
      condition: !!editData,
      message: "Subscription updated successfully",
      severity: "success",
    },
    {
      condition: !!isDeleteError,
      message: "Error while deleting the subscription",
      severity: "error",
    },
    {
      condition: !!isDeleteSuccess,
      message: "Subscription deleted successfully",
      severity: "success",
    },
    {
      condition: !!errorPostSubscriptionBucket,
      message:
        getError(errorPostSubscriptionBucket) ?? "Error while adding credits",
      severity: "error",
    },
    {
      condition: !!isSuccessPostSubscriptionBucket,
      message: "Credits added successfully",
      severity: "success",
    },
  ]);

  if (!subscription) {
    return (
      <FlexDiv>
        <span> Please select a subscription</span>
      </FlexDiv>
    );
  }

  const updateSubscription = (inputs: SubscriptionInfoInputs) => {
    editSubscription(inputs);
  };

  const updateEnabledUsers = (enabledUsers: {
    Basic: number;
    Manager: number;
  }) => {
    updateSubscription({
      enabledUsers,
      subscriptionId: subscription.id,
    });
  };

  const handleChangeCompanyName = () => {
    updateSubscription({
      companyName: name,
      subscriptionId: subscription.id,
    });
  };

  const handleChangeEmailIntegrationType = (value: EmailIntegrationType) => {
    setEmailIntegrationType(value);
    updateSubscription({
      emailIntegrationType: value,
      subscriptionId: subscription.id,
    });
  };

  function updateExpirationDate(value: Date | null) {
    openConfirmModal({
      title: "Are you sure you want to change the expiration date?",
      text: "New expiration date: " + moment(value).format("MM/DD/YYYY"),
      cancelText: "No",
      confirmText: "Yes",
      confirmFunction: () => {
        if (subscription) {
          setExpirationDate(value);
          updateSubscription({
            expirationDate: value,
            subscriptionId: subscription.id,
          });
        }
      },
      cancelFunction: () => null,
    });
  }

  function updateCreditsRenewalDate(value: Date | null) {
    openConfirmModal({
      title: "Are you sure you want to change the credits reneal date?",
      text: "New credits renewal date: " + moment(value).format("MM/DD/YYYY"),
      cancelText: "No",
      confirmText: "Yes",
      confirmFunction: () => {
        if (subscription) {
          setCreditsRenewalDate(value);
          updateSubscription({
            creditsRenewalDate: value,
            subscriptionId: subscription.id,
          });
        }
      },
      cancelFunction: () => null,
    });
  }

  function updateWebsite(value: string) {
    if (subscription) {
      if (!checkIfValidWebsite(value)) {
        setError("Please enter a valid website");
        setCompanyWebsite(subscription.company.website);
        return;
      }
      updateSubscription({
        companyWebsite: value,
        subscriptionId: subscription.id,
      });
    }
  }

  function updateSubscriptionDomains(value: string[]) {
    if (subscription) {
      updateSubscription({
        subscriptionDomains: value,
        subscriptionId: subscription.id,
      });
    }
  }

  function updateCoupon(value: string) {
    if (subscription) {
      updateSubscription({
        coupon: value,
        subscriptionId: subscription.id,
      });
    }
  }

  function updatePriceId(value: string) {
    if (subscription) {
      updateSubscription({
        priceId: value,
        subscriptionId: subscription.id,
      });
    }
  }

  function updateLinkedinUrl(value: string) {
    if (subscription) {
      updateSubscription({
        companyLinkedinUrl: value,
        subscriptionId: subscription.id,
      });
    }
  }

  const handleActiveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextStatus = e.target.checked;
    openConfirmModal({
      title: "Are you sure you want to change active status?",
      text: "New status: " + (nextStatus ? "Active" : "Inactive"),
      cancelText: "No",
      confirmText: "Yes",
      confirmFunction: () => {
        if (subscription) {
          setActive(nextStatus);
          updateSubscription({
            active: nextStatus,
            subscriptionId: subscription.id,
          });
        }
      },
      cancelFunction: () => null,
    });
  };

  function formatCredits(
    usedCredits: number | null | undefined,
    total: number | null | undefined,
    left: number | null | undefined
  ): string | number | readonly string[] | undefined {
    if (usedCredits === null || total === null || left === null) {
      return "N/A";
    }

    return `${usedCredits}/${total} (${left} left)`;
  }

  function formatScrapeResume(
    found: number | null | undefined,
    lastMonth: number | null | undefined
  ): string | number | readonly string[] | undefined {
    if (found === null || lastMonth === null) {
      return "N/A";
    }

    return `${found} (${lastMonth} last month)`;
  }

  function formatBucketCredits(
    usedCredits: number | null | undefined,
    total: number | null | undefined
  ): string | number | readonly string[] | undefined {
    if (usedCredits === null || total === null) {
      return "N/A";
    }

    return `${usedCredits}/${total}`;
  }

  const handleOpenAddCreditsInBucket = (type: CreditsType) => {
    setSelectedCreditTypeToAdd(type);
  };

  const handleAddCreditBuckets = () => {
    postSubscriptionBucket({
      quantity: Number(numberOfCredits),
      subscriptionId: subscription.id,
      type: selectedCreditTypeToAdd!,
    });
  };

  const handleOpenDomains = () => {
    setShowDomainsModal(true);
  };

  return (
    <SubscriptionInfoWrapper>
      {selectedCreditTypeToAdd &&
        createPortal(
          <AddCreditWrapper
            id="add-credit-wrapper"
            onClick={() => {
              setSelectedCreditTypeToAdd(null);
            }}
          >
            <div
              className="container"
              onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography fontSize={24} lineHeight={"30px"} fontWeight={700}>
                  Add credits to the bucket
                </Typography>
                <Close
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedCreditTypeToAdd(null);
                  }}
                />
              </div>
              <Select
                value={selectedCreditTypeToAdd}
                onChange={(ev) => {
                  setSelectedCreditTypeToAdd(ev.target.value as CreditsType);
                }}
              >
                <MenuItem key="Email" value={"Email"}>
                  Email
                </MenuItem>
                <MenuItem key="Phone" value={"Phone"}>
                  Phone
                </MenuItem>
                <MenuItem key="Template" value={"Template"}>
                  Template
                </MenuItem>
              </Select>
              <TextField
                variant="standard"
                value={numberOfCredits}
                onChange={(ev) => {
                  setNumberOfCredits(ev.target.value);
                }}
                error={nanValueDetected}
                helperText={
                  nanValueDetected ? "Please enter a number" : undefined
                }
              />
              <FlexDiv justifyContent="end">
                {isLoadingPostSubscriptionBucket ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant="contained"
                    className="submit-add-button"
                    disabled={nanValueDetected}
                    onClick={handleAddCreditBuckets}
                  >
                    Add
                  </Button>
                )}
              </FlexDiv>
            </div>
          </AddCreditWrapper>,
          document.body
        )}
      {showDomainsModal &&
        createPortal(
          <EditDomainsModal
            close={() => setShowDomainsModal(false)}
            initialDomains={subscriptionDomains}
            onSubmit={updateSubscriptionDomains}
          />,
          document.body
        )}
      <FlexDiv
        height="unset"
        className="accountInfo-card-title"
        justifyContent="space-between"
        alignItems="start"
      >
        <h3 className="account-info">
          Subscription info -{" "}
          <span className="account-name">{subscription.company.name}</span>
        </h3>
        <span className="prospect-add">
          {admin?.canCreate &&
            (isDeleteLoading ? (
              <CircularProgress size={20} />
            ) : (
              <Delete
                className="pointer"
                color="error"
                onClick={() =>
                  openConfirmModal({
                    title: "Are you sure you want to delete this subscription?",
                    text: "This action is irreversible",
                    cancelText: "No",
                    confirmText: "Yes",
                    confirmFunction: () => {
                      if (subscription) {
                        deleteSubscription({
                          subscriptionId: subscription.id,
                        });
                      }
                    },
                    cancelFunction: () => null,
                  })
                }
              />
            ))}
        </span>
      </FlexDiv>
      <div className="card-content">
        <div className="account-info-row">
          <p>Company name</p>
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="name"
            name="name"
            value={name}
            type="text"
            onChange={(event) => setName(event.target.value)}
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                //The onBlur will call the API
                event.target.blur();
              }
            }}
            onBlur={handleChangeCompanyName}
            disabled={!admin?.canModify}
          />
        </div>
        <div className="account-info-row">
          <p>Company website</p>
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="companyWebsite"
            name="companyWebsite"
            value={companyWebsite ?? ""}
            disabled={admin?.canModify ? false : true}
            onChange={(e: any) => {
              const value = e.target.value;
              setCompanyWebsite(value);
            }}
            onBlur={(e: any) => {
              if (companyWebsite) updateWebsite(companyWebsite);
            }}
            type="text"
          />
        </div>
        <div className="account-info-row">
          <p>Company Linkedin Url</p>
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="companyLinkedinUrl"
            name="companyLinkedinUrl"
            value={companyLinkedinUrl ?? ""}
            disabled={admin?.canModify ? false : true}
            onChange={(e: any) => {
              const value = e.target.value;
              setCompanyLinkedinUrl(value);
            }}
            onBlur={(e: any) => {
              if (companyLinkedinUrl) updateLinkedinUrl(companyLinkedinUrl);
            }}
            type="text"
          />
        </div>
        <div className="account-info-row">
          <p>Subscription Id</p>
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="id"
            name="id"
            value={subscription.id}
            type="text"
          />
        </div>
        <div className="account-info-row">
          <p>Active</p>
          <ActiveSwitch
            id="active"
            name="active"
            disabled={admin?.canModify ? false : true}
            checked={active}
            onChange={handleActiveChange}
          />
        </div>
        <div className="account-info-row">
          <p>Integration Type</p>
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="integrationType"
            name="integrationType"
            value={integrationType}
            type="text"
          />
        </div>
        <div className="account-info-row">
          <p>Subscription Type</p>
          <Select
            className="account-info-select"
            id="subscriptionType"
            name="subscriptionType"
            value={subscriptionType}
            disabled={admin?.canModify ? false : true}
            defaultValue={subscriptionType}
            onChange={(e: any) => {
              const value = e.target.value;
              setSubscriptionType(value);
              updateSubscription({
                subscriptionType: value,
                subscriptionId: subscription.id,
              });
            }}
          >
            <MenuItem key="freetrial" value={SubscriptionType.FREE_TRIAL}>
              Free Trial
            </MenuItem>
            <MenuItem key="starter" value={SubscriptionType.STARTER}>
              Starter
            </MenuItem>
            <MenuItem key="pro" value={SubscriptionType.PRO}>
              Pro
            </MenuItem>
            <MenuItem key="ultra" value={SubscriptionType.ULTRA}>
              Ultra
            </MenuItem>
          </Select>
        </div>
        <div className="account-info-row">
          <p>Email Integration Type</p>
          <Select
            className="account-info-select"
            id="emailIntegrationType"
            name="emailIntegrationType"
            value={emailIntegrationType}
            disabled={!admin?.canModify}
            defaultValue={emailIntegrationType}
            onChange={(e: any) => {
              handleChangeEmailIntegrationType(e.target.value);
            }}
          >
            {Object.values(EmailIntegrationType).map((type) => {
              return (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <div className="account-info-row">
          <p>Call Integration Type</p>
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="callIntegrationType"
            name="callIntegrationType"
            value={callIntegrationType}
            type="text"
          />
        </div>
        <div className="account-info-row">
          <p>Expiration Date</p>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={expirationDate}
              inputFormat="dd/MM/yyyy"
              disabled={admin?.canModify ? false : true}
              open={expirationDatePickerOpen}
              onChange={(value) => updateExpirationDate(value)}
              onClose={() => setExpirationDatePickerOpen(false)}
              renderInput={(params) => (
                <TextField
                  className="input-date"
                  fullWidth
                  {...params}
                  disabled
                  onClick={() => {
                    setExpirationDatePickerOpen(true);
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="account-info-row">
          <p>MultiCustomer</p>
          <ActiveSwitch
            id="active"
            name="active"
            disabled={true}
            checked={subscription.multiCustomer}
          />
        </div>
        {subscription.multiCustomer && (
          <>
            <div className="account-info-row">
              <p>Subscription Domain</p>
              <ChipInput
                className="account-info-chip"
                textAlign={"center"}
                id="subscriptionDomains"
                name="subscriptionDomains"
                value={subscriptionDomains?.join(", ") ?? ""}
                type="text"
                disabled={true}
              />
              <EditRounded
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (admin?.canModify) handleOpenDomains();
                }}
              />
              {/**
               * <ChipInput
                className="account-info-chip"
                textAlign={"center"}
                id="subscriptionDomains"
                name="subscriptionDomains"
                value={subscriptionDomains ?? ""}
                type="text"
                disabled={admin?.canModify ? false : true}
                onChange={(e: any) => {
                  const value = e.target.value;
                  setSubscriptionDomains(value);
                }}
                onBlur={(e: any) => {
                  if (subscriptionDomains)
                    updateSubscriptionDomains(subscriptionDomains);
                }}
              />
               */}
            </div>
            <div className="account-info-row">
              <p>Price Id</p>
              <ChipInput
                className="account-info-chip"
                textAlign={"center"}
                id="priceId"
                name="priceId"
                value={priceId ?? ""}
                type="text"
                disabled={admin?.canModify ? false : true}
                onChange={(e: any) => {
                  const value = e.target.value;
                  setPriceId(value);
                }}
                onBlur={(e: any) => {
                  if (priceId || priceId === "") updatePriceId(priceId);
                }}
              />
            </div>
            <div className="account-info-row">
              <p>Coupon</p>
              <ChipInput
                className="account-info-chip"
                textAlign={"center"}
                id="coupon"
                name="coupon"
                value={coupon ?? ""}
                type="text"
                disabled={admin?.canModify ? false : true}
                onChange={(e: any) => {
                  const value = e.target.value;
                  setCoupon(value);
                }}
                onBlur={(e: any) => {
                  if (coupon || coupon === "") updateCoupon(coupon);
                }}
              />
            </div>
          </>
        )}
        <div className="account-info-row">
          <p>Credits renewal Date</p>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={creditsRenewalDate}
              inputFormat="dd/MM/yyyy"
              disabled={admin?.canModify ? false : true}
              open={creditsRenewalDatePickerOpen}
              onClose={() => setCreditsRenewalDatePickerOpen(false)}
              onChange={(value) => updateCreditsRenewalDate(value)}
              renderInput={(params) => (
                <TextField
                  className="input-date"
                  fullWidth
                  {...params}
                  disabled
                  onClick={() => setCreditsRenewalDatePickerOpen(true)}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="account-info-row">
          <p>Max Basic Users</p>
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="basicUserQuantity"
            name="basicUserQuantity"
            value={basicUserQuantity}
            disabled={admin?.canModify ? false : true}
            onChange={(e: any) => {
              const value = e.target.value;
              if (isNaN(Number(value))) {
                setError("Please enter a number");
                return;
              }
              const numberValue = Number(value);
              setBasicUserQuantity(numberValue);
            }}
            onBlur={() => {
              updateEnabledUsers({
                Basic: basicUserQuantity,
                Manager: managerUserQuantity,
              });
            }}
            type="text"
          />
        </div>
        <div className="account-info-row">
          <p>Max Manager Users</p>
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="managerUserQuantity"
            name="managerUserQuantity"
            value={managerUserQuantity}
            disabled={admin?.canModify ? false : true}
            onChange={(e: any) => {
              const value = e.target.value;
              if (isNaN(Number(value))) {
                setError("Please enter a number");
                return;
              }
              const numberValue = Number(value);
              setManagerUserQuantity(numberValue);
            }}
            onBlur={() => {
              updateEnabledUsers({
                Basic: basicUserQuantity,
                Manager: managerUserQuantity,
              });
            }}
            type="text"
          />
        </div>
        <div className="account-info-row">
          <p>Email Credits</p>
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="emailCredits"
            value={formatCredits(
              subscription.credits?.emailCredits,
              subscription.credits?.emailTotal,
              subscription.credits?.emailLeft
            )}
            type="text"
          />
        </div>
        <div className="account-info-row">
          <p>Email Buckets</p>
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="emailBuckets"
            value={formatBucketCredits(
              subscription.credits?.emailBucketsCredits,
              subscription.credits?.emailBucketsTotal
            )}
            type="text"
            style={{
              width: "calc(100% - 70px)",
            }}
          />
          <IconButton
            className="add-credits-button"
            onClick={() => handleOpenAddCreditsInBucket("Email")}
          >
            <Add />
          </IconButton>
        </div>
        <div className="account-info-row">
          <p>Phone Credits</p>
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="phoneCredits"
            value={formatCredits(
              subscription.credits?.phoneCredits,
              subscription.credits?.phoneTotal,
              subscription.credits?.phoneLeft
            )}
            type="text"
          />
        </div>
        <div className="account-info-row">
          <p>Phone Buckets</p>
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="phoneBuckets"
            value={formatBucketCredits(
              subscription.credits?.phoneBucketsCredits,
              subscription.credits?.phoneBucketsTotal
            )}
            type="text"
            style={{
              width: "calc(100% - 70px)",
            }}
          />
          <IconButton
            className="add-credits-button"
            onClick={() => handleOpenAddCreditsInBucket("Phone")}
          >
            <Add />
          </IconButton>
        </div>
        <div className="account-info-row">
          <p>Template Credits</p>
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="templateCredits"
            value={formatCredits(
              subscription.credits?.assistantCredits,
              subscription.credits?.assistantTotal,
              subscription.credits?.assistantLeft
            )}
            type="text"
          />
        </div>
        <div className="account-info-row">
          <p>Template Buckets</p>
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="templateBuckets"
            value={formatBucketCredits(
              subscription.credits?.templateBucketsCredits,
              subscription.credits?.templateBucketsTotal
            )}
            type="text"
            style={{
              width: "calc(100% - 70px)",
            }}
          />
          <IconButton
            className="add-credits-button"
            onClick={() => handleOpenAddCreditsInBucket("Template")}
          >
            <Add />
          </IconButton>
        </div>
        <div className="account-info-row">
          <p>Emails Found</p>
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="emailsFound"
            value={formatScrapeResume(
              subscription.scrapeResume?.emailsFound,
              subscription.scrapeResume?.emailsFoundLastMonth
            )}
            type="text"
          />
        </div>
        <div className="account-info-row">
          <p>Emails Bounced</p>
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="emailsBounced"
            value={formatScrapeResume(
              subscription.scrapeResume?.emailsBounced,
              subscription.scrapeResume?.emailsBouncedLastMonth
            )}
            type="text"
          />
        </div>
        <div className="account-info-row">
          <p>Emails Bounced Shielded</p>
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="emailsBouncedShielded"
            value={formatScrapeResume(
              subscription.scrapeResume?.emailsBouncedShielded,
              subscription.scrapeResume?.emailsBouncedShieldedLastMonth
            )}
            type="text"
          />
        </div>
        <div className="account-info-row">
          <p>Emails Not Found</p>
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="emailsNotFound"
            value={formatScrapeResume(
              subscription.scrapeResume?.emailsNotFound,
              subscription.scrapeResume?.emailsNotFoundLastMonth
            )}
            type="text"
          />
        </div>
        <div className="account-info-row">
          <p>Phones Found</p>
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="phonesFound"
            value={formatScrapeResume(
              subscription.scrapeResume?.phonesFound,
              subscription.scrapeResume?.phonesFoundLastMonth
            )}
            type="text"
          />
        </div>
        <div className="account-info-row">
          <p>Phones Wrong Number</p>
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="phonesWrongNumber"
            value={formatScrapeResume(
              subscription.scrapeResume?.phonesWrongNumber,
              subscription.scrapeResume?.phonesWrongNumberLastMonth
            )}
            type="text"
          />
        </div>
        <div className="account-info-row">
          <p>Phones Not Found</p>
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="phonesNotFound"
            value={formatScrapeResume(
              subscription.scrapeResume?.phonesNotFound,
              subscription.scrapeResume?.phonesNotFoundLastMonth
            )}
            type="text"
          />
        </div>
      </div>
    </SubscriptionInfoWrapper>
  );
};

export default SubscriptionInfo;
