import styled from "styled-components";
import { colors } from "styles/theme";

export const SubscriptionsGridWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const SubscriptionsListWrapper = styled.div`
  width: 100%;

  .account-item-container {
    width: 100%;
    padding: 20px 10px;

    p {
      margin-left: 10px;
    }
  }
`;

export const SubscriptionHintWrapper = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
`;

export const TooltipContentWrapper = styled.div`
  text-align: center;
`;

export const SubscriptionInfoWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 100%;
  padding: 0px 10px;

  p {
    margin: 2px 2px 2px 10px;
  }

  .subscription-avatar {
    background-color: ${colors.backgroundAvatar};
    color: ${colors.blue};
    font-size: 1rem;
  }

  .subscription-avatar-inactive {
    background-color: ${colors.backgroundAvatarDisabled};
    font-size: 1rem;
  }

  .subscription-avatar-container {
    padding: 0px 10px;
    min-width: 150px;
    overflow: auto;
    padding: 4px;
    margin: 8px 0px;
  }

  .mask {
    border: 1px solid ${colors.primary};
    border-radius: 5px;
    background-color: ${colors.backGroundPrimary};
  }

  .selected-text {
    color: ${colors.blue};
    font-weight: 600;
  }

  .inactive {
    color: ${colors.grey};
  }
`;

export const SubscriptionRowWrapper = styled.div`
  @media (min-width: 900px) {
    .subscription-row {
      border: 1px solid ${colors.background};
    }
    .subscription-cell {
      cursor: pointer;
    }
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 100%;
  border-top: 3px solid ${colors.background};

  .pagination {
    display: inline-block;
    padding-left: 0;
    border-radius: 4px;
    margin: 2px auto;
  }
  .pagination > li {
    display: inline;
  }
  .pagination > li > a,
  .pagination > li > span {
    font-size: 12px;
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: ${colors.primary};
    background-color: transparent;
    border-radius: 1rem;
    margin-left: -1px;
    cursor: pointer;
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
  .pagination > li > a:hover,
  .pagination > li > span:hover,
  .pagination > li > a:focus,
  .pagination > li > span:focus {
    z-index: 2;
    color: #ffffff;
    background-color: ${colors.primary};
    border-color: #ddd;
  }
  .pagination > .active > a,
  .pagination > .active > span,
  .pagination > .active > a:hover,
  .pagination > .active > span:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span:focus {
    z-index: 3;
    color: #ffffff;
    background-color: ${colors.primary};
    border-color: #2c689c;
    cursor: default;
  }
  .pagination > .disabled > span,
  .pagination > .disabled > span:hover,
  .pagination > .disabled > span:focus,
  .pagination > .disabled > a,
  .pagination > .disabled > a:hover,
  .pagination > .disabled > a:focus {
    color: #777777;
    border-color: #ddd;
    cursor: not-allowed;
  }
  .pagination-lg > li > a,
  .pagination-lg > li > span {
    padding: 10px 16px;
    font-size: 20px;
    line-height: 1.3333333;
  }
  .pagination-lg > li:first-child > a,
  .pagination-lg > li:first-child > span {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .pagination-lg > li:last-child > a,
  .pagination-lg > li:last-child > span {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
  .pagination-sm > li > a,
  .pagination-sm > li > span {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.5;
  }
  .pagination-sm > li:first-child > a,
  .pagination-sm > li:first-child > span {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
  .pagination-sm > li:last-child > a,
  .pagination-sm > li:last-child > span {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }
`;

export const SubscriptionFilterWrapper = styled.div`
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 3px solid ${colors.background};

  .btn-container {
    padding-left: 4px;
    padding-right: 4px;
    font-size: 11px;
  }

  .status-btn {
    width: 100%;
    border-radius: 10px;
    padding: 2px 4px;
    text-align: center;
    cursor: pointer;
  }

  .active {
    color: ${colors.success};
    border: 1px solid ${colors.success};
  }

  .active-selected {
    color: ${colors.white};
    background-color: ${colors.success};
  }

  .inactive {
    color: ${colors.red};
    border: 1px solid ${colors.red};
  }

  .inactive-selected {
    color: ${colors.white};
    background-color: ${colors.red};
  }

  .nurture {
    color: ${colors.blue};
    border: 1px solid ${colors.blue};
  }

  .nurture-selected {
    color: ${colors.white};
    background-color: ${colors.blue};
  }

  .working {
    color: ${colors.primary};
    border: 1px solid ${colors.primary};
  }

  .working-selected {
    color: ${colors.white};
    background-color: ${colors.primary};
  }

  .call-scheduled {
    color: ${colors.green};
    border: 1px solid ${colors.green};
  }

  .call-scheduled-selected {
    color: ${colors.white};
    background-color: ${colors.green};
  }

  .open-opportunity {
    color: ${colors.primary};
    border: 1px solid ${colors.primary};
  }

  .open-opportunity-selected {
    color: ${colors.white};
    background-color: ${colors.primary};
  }

  .customer {
    color: ${colors.yellow};
    border: 1px solid ${colors.yellow};
  }

  .customer-selected {
    color: ${colors.white};
    background-color: ${colors.yellow};
  }
`;

export const SubscriptionStatusWrapper = styled.div`
  width: 100%;
  height: 100%;

  .controls-container {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    user-select: none;
  }

  @media (max-width: 1200px) {
    .controls-container {
      grid-template-columns: repeat(3, 2fr);
    }
  }

  .control {
    position: relative;
    border: 1px solid ${colors.lightGrey};
    cursor: pointer;
    p {
      text-align: center;
    }
  }

  .red {
    background-color: ${colors.backgroundRed};
    color: ${colors.red};
    border-left: 3px solid ${colors.red};
  }

  .blue {
    background-color: ${colors.backgroundBlue};
    color: ${colors.blue};
    border-left: 3px solid ${colors.blue};
  }

  .light-blue {
    background-color: ${colors.backgroundLightBlue};

    color: ${colors.lightBlue};
    border-left: 3px solid ${colors.lightBlue};
  }

  .primary {
    background-color: ${colors.backGroundPrimary};
    color: ${colors.primary};
    border-left: 3px solid ${colors.primary};
  }

  .green {
    background-color: ${colors.backgroundGreen};
    color: ${colors.green};
    border-left: 3px solid ${colors.green};
  }

  .yellow {
    background-color: ${colors.backgroundYellow};
    color: ${colors.yellow};
    border-left: 3px solid ${colors.yellow};
  }
`;
