import Home from "pages/home";
import Login from "pages/login";
import Settings from "pages/settings";
import PATH from "./path";
import { NamedRoute } from "./types";

//module routes
import NotFoundHandler from "./NotFoundHandler";

export const Routes: NamedRoute[] = [
  {
    name: "Home",
    path: PATH.HOME,
    element: <Home />,
    index: true,
  },
  {
    name: "Settings",
    path: PATH.SETTINGS,
    element: <Settings />,
    index: true,
  },
  {
    name: "Login",
    path: PATH.LOGIN,
    element: <Login />,
    index: true,
  },
  {
    name: "Not found",
    path: "*",
    element: <NotFoundHandler />,
    index: true,
  },
];
