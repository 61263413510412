import React, { FC, useState } from "react";
import { FlexDiv } from "components/Layout/FlexDiv";
import { CircularProgress, Stack, Tab, Tabs } from "@mui/material";
import { useSelectedSubscription } from "../context/subscription";
import { TabPanel, TabContext } from "@mui/lab";
import { SubscriptionDataWrapper } from "./styles";
import OtherSettingsInfo from "../OtherSettingsInfo";

const SubscriptionData: FC = () => {
  const { subscription } = useSelectedSubscription();

  const [activeTab, setActiveTab] = useState<"settings">("settings");

  if (!subscription)
    return (
      <FlexDiv>
        <span> Please select a subscription</span>
      </FlexDiv>
    );

  return (
    <SubscriptionDataWrapper>
      <TabContext value={activeTab}>
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
        >
          <Tab
            component={React.forwardRef((props, ref) => (
              <Stack
                className={`tab-element ${
                  activeTab === "settings" ? "tab-element-selected" : ""
                }`}
                justifyContent="center"
                alignItems="center"
                flexDirection="row"
                padding={1}
                onClick={() => setActiveTab("settings")}
                gap={1}
              >
                {activeTab === "settings" ? (
                  <>
                    {`Other Settings - `}
                    <b className="tab-user-name">
                      {subscription?.company.name}
                    </b>
                  </>
                ) : (
                  <>Other Settings</>
                )}
              </Stack>
            ))}
            value="settings"
          />
        </Tabs>
        <TabPanel value="settings">
          <OtherSettingsInfo />
        </TabPanel>
      </TabContext>
    </SubscriptionDataWrapper>
  );
};

export default SubscriptionData;
