import styled from "styled-components";
import { colors } from "styles/theme";

export const FullPageErrorFallback = styled.div`
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.background};

  .error-message {
    font-size: 24px;
    margin-bottom: 10px;
  }
`;
