import useGetSubscription from "hooks/services/subscription/useGetSubscription";
import { createContext, FC, useContext, useState, useEffect } from "react";
import { Subscription } from "types/subscription";
import { SubscriptionSearchParameters } from "types/subscriptionSearchParameters";
import { Constants } from "utils/constants/constants";
import { SubscriptionContextType } from "./types";

export const SubscriptionContext = createContext<
  SubscriptionContextType | undefined
>(undefined);

SubscriptionContext.displayName = "SubscriptionContext";

const SubscriptionProvider: FC = ({ children }) => {
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<
    number | null
  >(null);
  const [searchParameters, setSearchParameters] =
    useState<SubscriptionSearchParameters>({
      limit: Constants.defaultLimit,
      name: null,
      type: null,
    });

  const { data: subscriptionData, isRefetching } = useGetSubscription(
    selectedSubscriptionId ?? undefined,
    true
  );

  useEffect(() => {
    if (isRefetching) return;
    if (subscriptionData && subscriptionData.data) {
      setSubscription(subscriptionData.data);
    }
  }, [subscriptionData, isRefetching]);

  return (
    <SubscriptionContext.Provider
      value={{
        subscription,
        setSubscription,
        searchParameters,
        setSearchParameters,
        selectedSubscriptionId,
        setSelectedSubscriptionId,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionProvider;

export function useSelectedSubscription() {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error(`useAccount must be used within a AccountProvider`);
  }

  return context;
}
