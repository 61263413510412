import { ENDPOINTS } from "config/endpoints";
import { useQuery } from "react-query";
import Error from "types/error";
import client from "utils/clients/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { Subscription } from "types/subscription";

export default function useGetSubscription(
  id?: number,
  withUsers: boolean = false
) {
  const requestConfig = {
    queryKey: [ENDPOINTS.subscription.main, id],
    queryFn: () => {
      const request: Request = {
        url: `${ENDPOINTS.subscription.main}/${id}?withUsers=${withUsers}`,
        config: {
          method: "get",
        },
      };
      return client(request).then((res) => res.data);
    },
    enabled: !!id,
  };

  return useQuery<Response<Subscription>, Error>(requestConfig);
}
