import { createTheme } from "@mui/material/styles";

export const colors = {
  white: "#fff",
  grey: "#222",
  lightGrey: "#f8f9fd",
  mediumGrey: "#666666",
  black: "#333",
  iconBlack: "#33333354",
  primary: "#07C8C0",
  backGroundPrimary: "#07C8C020",
  background: "#F7F8FB",
  red: "#F32424",
  error: "#F32424",
  backgroundRed: "#F3242414", 
  blue: "#1F84CD",
  backgroundAvatar: "#1F84CD30",
  backgroundAvatarDisabled: "#BDBDBD",
  backgroundBlue: "#2483F314",
  lightBlue: "#2AC7EA",
  backgroundLightBlue: "#2AC7EA14",
  green: "#0AD437",
  backgroundGreen: "#0AD43714",
  yellow: "#FF9F2E",
  lightYellow: "#FFC000",
  backgroundYellow: "#FF9F2E14",
  dialerBlue: "#1E394C",
  dialerBackground: "#F8F9FD",
  dialerGreen: "#6BCC92",
  placeholder: "#00000048",
  linkedin: "#0077B5",
  chart1: "#AC92EB",
  chart1Lightened: "#DFC5FF",
  chart2: "#4FC1E8",
  chart3: "#A0D568",
  chart3Lightened: "#EDFFB5",
  chart4: "#FFCE54",
  chart5: "#ED5564",
  success: "#00c851",
};

export const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    error: {
      main: colors.error,
    },
  },
});
