import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { createContext, FC, useContext, useEffect, useState } from "react";
import { ConfirmModalContent, ConfirmModalContextType } from "./types";

export const ConfirmModalContext = createContext<
  ConfirmModalContextType | undefined
>(undefined);
ConfirmModalContext.displayName = "ConfirmModalContext";

const ConfirmModalProvider: FC = ({ children }) => {
  const [confirmModalContent, setConfirmModalContent] =
    useState<ConfirmModalContent | null>(null);

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    if (confirmModalContent)
      MySwal.fire({
        title: confirmModalContent.title || "Are you sure?",
        showCancelButton: true,
        cancelButtonText: confirmModalContent.cancelText || "Cancel",
        confirmButtonText: confirmModalContent.confirmText || "Confirm",
        showCloseButton: true,
        heightAuto: false,
        customClass: "confirm-modal",
        icon: "warning",
        ...(confirmModalContent.text && { text: confirmModalContent.text }),
      }).then((result) => {
        if(result.dismiss === Swal.DismissReason.close) {
          return;
        }
        if (result.isConfirmed) {
          confirmModalContent.confirmFunction();
        }
        else{
          confirmModalContent.cancelFunction();
        }
        setConfirmModalContent(null);
      });
  }, [confirmModalContent]);

  return (
    <ConfirmModalContext.Provider value={{ openModal: setConfirmModalContent }}>
      {children}
    </ConfirmModalContext.Provider>
  );
};

export function useConfirmModal() {
  const context = useContext(ConfirmModalContext);
  if (context === undefined) {
    throw new Error(
      `useConfirmModal must be used within a ConfirmModalProvider`
    );
  }

  return context;
}

export default ConfirmModalProvider;
