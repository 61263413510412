import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/clients/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { SubscriptionInsert } from "types/subscriptionInsert";

export default function useCreateSubscription() {
  const queryClient = useQueryClient();

  const createSubscription = (subscriptionInput: SubscriptionInsert) => {
    const request: Request = {
      url: `${ENDPOINTS.subscription.main}`,
      config: {
        method: "post",
        data: { data: subscriptionInput },
      },
    };
    return client(request);
  };

  return useMutation<Response<null>, Error, SubscriptionInsert>(
    createSubscription,
    {
      onSettled: () => {
        queryClient.invalidateQueries([ENDPOINTS.subscription.main]);
      },
    }
  );
}
