import { FC } from "react";
import { SubscriptionInfoWrapper } from "./styles";
import { FlexDiv } from "components/Layout/FlexDiv";
import { Avatar, Tooltip } from "@mui/material";
import { SubscriptionInfoProps } from "./types";
import { getImageFromUrl } from "utils";
// import useImage from "hooks/services/image/useImage";

const SubscriptionInfo: FC<SubscriptionInfoProps> = ({
  selected,
  name,
  subscription,
}) => {
  return (
    <SubscriptionInfoWrapper className="info-container">
      <Tooltip title={""}>
        <FlexDiv
          justifyContent="start"
          className={`subscription-avatar-container ${selected ? "mask" : ""}`}
        >
          <Avatar
            src={
              (subscription.company.website &&
                getImageFromUrl(subscription.company.website, null)) ??
              ""
            }
            className={
              subscription.active
                ? "subscription-avatar"
                : "subscription-avatar-inactive"
            }
          >
            {name.slice(0, 2).toUpperCase()}
          </Avatar>
          <p
            className={`${
              (subscription.active ? "" : "not-owned ") +
              (selected ? "selected-text" : "")
            }`}
          >
            {name}
          </p>
        </FlexDiv>
      </Tooltip>
    </SubscriptionInfoWrapper>
  );
};

export default SubscriptionInfo;
