import styled from "styled-components";
import { colors } from "styles/theme";

const Page = styled.div`
  overflow-y: auto;
  height: 100%;
  background-color: ${colors.background};
  padding: 20px;

  @media screen and (max-width: 768px) {
    padding: 5px;
  }
`;

export default Page;
