import React, { FC, useState } from "react";
import { tooltipStyles, TopbarWrapper } from "./styles";
import Logo from "../../../assets/images/logo.png";
import SearchIcon from "@mui/icons-material/Search";
import LogoutIcon from "../../../assets/icons/logout.svg";
import SettingsIcon from "../../../assets/icons/setting.png";
import { FlexDiv } from "components/Layout/FlexDiv";
import { useNavigate } from "react-router-dom";
import PATH from "routing/path";
import { useAuth } from "context/auth";
import { useSearchBar } from "context/searchbar";
import Add2 from "assets/images/add_2.png";
import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddNewSubscriptionModal from "components/Modal/AddNewSubscriptionModal";
import AddNewSignupLinkModal from "components/Modal/AddNewSignupLinkModal";
import { IsHomeRoute } from "utils";

const Topbar: FC = () => {
  const { admin, logout } = useAuth();
  const { searchBarText, setSearchBarText } = useSearchBar();
  const useStyles = makeStyles(tooltipStyles)();

  const [openNewSubscription, setOpenNewSubscription] =
    useState<boolean>(false);

  const [openNewSignupLink, setOpenNewSignupLink] = useState<boolean>(false);

  const navigate = useNavigate();

  const route = window.location.pathname;

  return (
    <TopbarWrapper>
      <AddNewSubscriptionModal
        open={openNewSubscription}
        setOpen={setOpenNewSubscription}
      />
      <AddNewSignupLinkModal
        open={openNewSignupLink}
        setOpen={setOpenNewSignupLink}
      />

      <div className="logo">
        <img
          src={Logo}
          alt="Crono"
          className="top-bar-icon"
          onClick={() => navigate(PATH.HOME)}
        />
      </div>

      {admin && (
        <FlexDiv>
          {IsHomeRoute(route) && (
            <div className="search-wrapper">
              <SearchIcon className="search-icon" />
              <input
                type="text"
                className="search"
                placeholder="Search company"
                value={searchBarText}
                onChange={(event) => setSearchBarText(event.target.value)}
              />
            </div>
          )}
          {IsHomeRoute(route) && (
            <FlexDiv justifyContent="center" className="add-new">
              <Tooltip
                title={
                  <React.Fragment>
                    {admin.canCreate && (
                      <div
                        style={{
                          padding: "10px",
                          cursor: "pointer",
                          textAlign: "center",
                        }}
                        onClick={() => setOpenNewSubscription(true)}
                      >
                        New Subscription
                      </div>
                    )}
                    <div
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        textAlign: "center",
                      }}
                      onClick={() => setOpenNewSignupLink(true)}
                    >
                      Send signup link
                    </div>
                  </React.Fragment>
                }
                arrow
                classes={{ tooltip: useStyles.tooltip, arrow: useStyles.arrow }}
                disableFocusListener={true}
              >
                <img
                  className="top-bar-icon"
                  src={Add2}
                  alt="add"
                  width={"30px"}
                />
              </Tooltip>
            </FlexDiv>
          )}

          {!IsHomeRoute(route) && <FlexDiv width="140%" />}

          <FlexDiv justifyContent="end" className="settings">
            <img
              src={SettingsIcon}
              className="top-bar-icon"
              alt="Settings"
              onClick={() => navigate(PATH.SETTINGS)}
            />
          </FlexDiv>

          <FlexDiv justifyContent="end" className="user-info">
            {`${admin.username}`}
          </FlexDiv>
        </FlexDiv>
      )}
      {admin && (
        <FlexDiv justifyContent="end" className="logout">
          <img
            src={LogoutIcon}
            className="top-bar-icon"
            alt="Logout"
            onClick={() => logout()}
          />
        </FlexDiv>
      )}
    </TopbarWrapper>
  );
};

export default Topbar;
