import { ENDPOINTS } from "config/endpoints";
import { useQuery } from "react-query";
import Error from "types/error";
import client from "utils/clients/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { ScrapeMetricsResponse } from "types/scrapeMetricsResponse";

export default function useScrapeMetrics(since: Date | null) {
  // get key value object from searchParameters
  let queryString = "";

  if (since) {
    queryString = `since=${since.toISOString()}`;
  }

  const request: Request = {
    url: ENDPOINTS.scrape.metrics + "?" + queryString,
    config: {
      method: "get",
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.scrape.metrics, queryString],
    queryFn: () => client(request),
    // add cache
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  };

  const { data, ...rest } = useQuery<
    Response<{
      data: ScrapeMetricsResponse;
      total: number;
      count: number;
      limit: number;
      offset: number;
    }>,
    Error
  >(requestConfig);

  return { data, ...rest };
}
