import { FC } from "react";
import useOnMountAnimationMaker from "./MountAnimationMaker";
import { FadeProps } from "./types";

const Fade: FC<FadeProps> = ({ children, ...rest }) => {
  const animation = {
    to: { opacity: 1 },
    from: { opacity: 0 },
  };

  const FadeAnimation = useOnMountAnimationMaker({
    animation,
    ...rest,
  });

  return <FadeAnimation>{children}</FadeAnimation>;
};

export default Fade;
