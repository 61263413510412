import { Alert, Snackbar } from "@mui/material";
import { createContext, FC, useContext, useEffect, useState } from "react";
import {
  ConditionalSnackbar,
  SnackbarContent,
  SnackbarContextType,
} from "./types";

export const SnackbarContext = createContext<SnackbarContextType | undefined>(
  undefined
);
SnackbarContext.displayName = "SnackbarContext";

const SnackbarProvider: FC = ({ children }) => {
  const [snackbarContent, setSnackbarContent] =
    useState<SnackbarContent | null>(null);

  return (
    <SnackbarContext.Provider value={{ pushSnackbar: setSnackbarContent }}>
      {snackbarContent && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={true}
          autoHideDuration={6000}
          onClose={() => setSnackbarContent(null)}
        >
          <Alert
            onClose={() => setSnackbarContent(null)}
            severity={snackbarContent.severity}
            sx={{ width: "100%" }}
          >
            {snackbarContent.message}
          </Alert>
        </Snackbar>
      )}
      {children}
    </SnackbarContext.Provider>
  );
};

export function useSnackbar() {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error(`useSnackBar must be used within a SnackbarProvider`);
  }

  return context;
}

export function useConditionalSnackBar(
  conditionalSnackbar: ConditionalSnackbar[]
) {
  try {
    const { pushSnackbar } = useSnackbar();

    const conditions = conditionalSnackbar.map(
      (snackbar) => snackbar.condition
    );

    useEffect(() => {
      conditionalSnackbar.forEach((snackbar) => {
        const { condition, ...rest } = snackbar;
        condition && pushSnackbar(rest);
      });
    }, [...conditions]);
  } catch (e) {
    throw new Error(
      `useConditionalSnackBar must be used within a SnackbarProvider`
    );
  }
}

export default SnackbarProvider;
