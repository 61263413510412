import { FC } from "react";
import { MobileSearchBar, MobileTopbarWrapper } from "./styles";
import Logo from "../../../assets/images/logo.png";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import React from "react";
import { Logout, Settings } from "@mui/icons-material";
import { CollapsableSearchBar } from "./styles";
import { useAuth } from "context/auth";
import { useSearchBar } from "context/searchbar";
import { fullname } from "utils";

const MobileTopbar: FC = () => {
  const { admin } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [displaySearchBar, setDisplaySearchBar] =
    React.useState<boolean>(false);
  const open = Boolean(anchorEl);

  const { searchBarText, setSearchBarText } = useSearchBar();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleSearchBar = () => {
    setDisplaySearchBar((prev) => !prev);
  };

  return (
    <>
      <MobileTopbarWrapper>
        <div className="logo">
          <img src={Logo} alt="Crono" />
        </div>

        <div className="mobile-search-wrapper">
          <SearchIcon
            className="search-icon"
            onClick={toggleSearchBar}
            sx={{ color: "white" }}
          />
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}></Avatar>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {!!admin ? <MenuItem>{`${admin.username}`}</MenuItem> : null}
            <Divider />
            <MenuItem>
              <ListItemIcon>
                <IconButton>
                  <Settings />
                </IconButton>
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                <IconButton>
                  <Logout />
                </IconButton>
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </MobileTopbarWrapper>
      <CollapsableSearchBar in={displaySearchBar}>
        <MobileSearchBar>
          <input
            type="text"
            className="search"
            placeholder="Search company"
            value={searchBarText}
            onChange={(event) => setSearchBarText(event.target.value)}
          />
        </MobileSearchBar>
      </CollapsableSearchBar>
    </>
  );
};

export default MobileTopbar;
