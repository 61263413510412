import { Request } from "types/request";
import axios from "axios";
import { ADMIN_TOKEN_KEY } from "config/localStorage";

const client = (req: Request) => {
  const token = localStorage.getItem(ADMIN_TOKEN_KEY);
  const { url, config } = req;
  return axios(`${process.env.REACT_APP_BE_URL}/${url}`, {
    ...config,
    ...(token && {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  });
};

export default client;
