import { Button } from "@mui/material";
import styled from "styled-components";
import { colors } from "styles/theme";

export const CronoButton = styled(Button)`
  background-color: ${colors.primary} !important;
  text-transform: capitalize !important;
  color: ${colors.white} !important;
`;

export const ErrorButton = styled(Button)`
  background-color: ${colors.error} !important;
  text-transform: capitalize !important;
  color: ${colors.white} !important;
`;

export const SuccessButton = styled(Button)`
  background-color: ${colors.success} !important;
  text-transform: capitalize !important;
  color: ${colors.white} !important;
`;
