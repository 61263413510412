import { FlexDiv } from "components/Layout/FlexDiv";
import { FC } from "react";
import { Subscription } from "types/subscription";
import { ModalWrapper } from "./styles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AddUserForm from "pages/home/Users/addUser/addUserForm";

interface NewUserModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  subscription: Subscription;
}

const AddNewUserModal: FC<NewUserModalProps> = ({
  open,
  setOpen,
  subscription,
}) => {
  function handleClose() {
    setOpen(false);
  }

  return (
    <ModalWrapper
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-box">
        <FlexDiv
          justifyContent="space-between"
          alignItems="center"
          className="modal-header"
        >
          <h2>
            {subscription.multiCustomer ? "Invite new User" : "Add new User"}
          </h2>
          <CancelOutlinedIcon
            className="close-modal-icon"
            onClick={handleClose}
          />
        </FlexDiv>
        <div>
          <AddUserForm subscription={subscription} setOpen={setOpen} />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AddNewUserModal;
