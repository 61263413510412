import { ENDPOINTS } from "config/endpoints";
import { useQuery } from "react-query";
import Error from "types/error";
import client from "utils/clients/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { Subscription } from "types/subscription";

export default function useSubscriptionExists(
  name: string | null,
  editingComplete: boolean
) {
  // get key value object from searchParameters
  const queryString = new URLSearchParams({
    name: name ? name.trim() : "",
  }).toString();

  const request: Request = {
    url: ENDPOINTS.subscription.exists + "?" + queryString,
    config: {
      method: "get",
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.subscription.exists, queryString],
    queryFn: () => client(request),
    enabled: name !== null && name.length > 0 && editingComplete,
  };

  const { data, ...rest } = useQuery<
    Response<{
      data: Subscription[];
    }>,
    Error
  >(requestConfig);

  return { data, ...rest };
}
