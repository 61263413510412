import React, { SetStateAction } from "react";
import * as Yup from "yup";

export const validationSchema = Yup.object({
  email: Yup.string().email("Invalid Email").required("Email is required"),
  onlyPay: Yup.boolean(),
});

export interface SendSignupLinkInputs {
  email: string;
  onlyPay: boolean;
}

export const initialValues = {
  email: "",
  onlyPay: false,
};

export const userInitialValues = {
  email: "",
};

export interface SendSignupLinkProps {
  setOpen: React.Dispatch<SetStateAction<boolean>>;
}
