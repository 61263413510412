import { createContext, FC, useContext, useState } from "react";
import { SearchbarContextType } from "./types";

export const SearchbarContext = createContext<SearchbarContextType | undefined>(
  undefined
);
SearchbarContext.displayName = "SearchBarContext";

const SearchbarProvider: FC = ({ children }) => {
  const [searchBarText, setSearchBarText] = useState("");

  const value = {
    searchBarText,
    setSearchBarText,
  };

  return (
    <SearchbarContext.Provider value={value}>
      {children}
    </SearchbarContext.Provider>
  );
};

export default SearchbarProvider;

export function useSearchBar() {
  const context = useContext(SearchbarContext);
  if (context === undefined) {
    throw new Error(`useSearchBar must be used within a SearchbarProvider`);
  }

  return context;
}
