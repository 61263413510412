import { FC, useEffect, useState } from "react";
import Page from "components/Layout/Page";
import { Card } from "../../components/Card";

import Prospects from "./Users";
import SubscriptionInfo from "./SubscriptionInfo";
import Subscriptions from "./Subscriptions";
import Grid from "@mui/material/Grid";
import { HomeWrapper } from "./styles";
import { useAuth } from "context/auth";
import { FlexDiv } from "components/Layout/FlexDiv";
import { CircularProgress } from "@mui/material";
import { Fade } from "components/on-mount-animations";
import { animationConfig } from "config/animation";

import SubscriptionData from "./SubscriptionData";
import ScrapeMetricsView from "./ScrapeMetrics";
// import AlgorithmSettingsInfo from "./AlgorithmSettingsInfo";

const Home: FC = () => {
  const { admin, useAuthGuard } = useAuth();
  useAuthGuard();

  if (!admin)
    // todo maybe show something different for loading integration
    return (
      <FlexDiv>
        <CircularProgress />
      </FlexDiv>
    );

  return (
    <Page>
      <HomeWrapper>
        <Grid container spacing={3} className="layout-grid">
          <Grid item xs={12} md={8} className="layout-grid-item">
            <Card padding="0">
              <Fade
                delay={animationConfig.delay}
                duration={animationConfig.duration}
              >
                <Subscriptions />
              </Fade>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} className="layout-grid-item">
            <Card>
              <Fade
                delay={animationConfig.delay}
                duration={animationConfig.duration}
              >
                <ScrapeMetricsView />
              </Fade>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} className="layout-grid-item">
            <Card>
              <Fade
                delay={animationConfig.delay}
                duration={animationConfig.duration}
              >
                <SubscriptionInfo />
              </Fade>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} className="layout-grid-item">
            <Card>
              <Fade
                delay={animationConfig.delay}
                duration={animationConfig.duration}
              >
                <Prospects />
              </Fade>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} className="layout-grid-item">
            <Card className="tabs-item">
              <Fade
                delay={animationConfig.delay}
                duration={animationConfig.duration}
              >
                {<SubscriptionData />}
              </Fade>
            </Card>
          </Grid>
        </Grid>
      </HomeWrapper>
    </Page>
  );
};

export default Home;
