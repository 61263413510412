import { FC, useState } from "react";
import { ProspectWrapper } from "./styles";
import { Avatar, Tooltip } from "@mui/material";
import { UserProps } from "./type";
import { fullname } from "utils";
import { Collapse } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { colors } from "styles/theme";
import { FlexDiv } from "components/Layout/FlexDiv";
import UserInfo from "./UserInfo";

const User: FC<UserProps> = ({
  user,
  subscription /*, openModal, setOpenModal, actionType, setActionType */,
}) => {
  const [openDetail, setOpenDetail] = useState<boolean>(false);

  const stringAvatar = (name: string) =>
    name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  return (
    <>
      <ProspectWrapper>
        <FlexDiv
          width="100%"
          className="prospect-container-top"
          justifyContent="start"
        >
          <Tooltip
            title={fullname(user.firstName, user.lastName)}
            placement="bottom"
          >
            <Avatar
              className={
                user.active ? "prospect-avatar" : "prospect-avatar-not-owned"
              }
              alt={fullname(user.firstName, user.lastName)}
              sx={{ height: "30px", width: "30px", fontSize: "14px" }}
            >
              {stringAvatar(fullname(user.firstName, user.lastName))}
            </Avatar>
          </Tooltip>
          <FlexDiv
            width="unset"
            direction="column"
            alignItems="start"
            className="prospectInfo"
            onClick={() => setOpenDetail((prev) => !prev)}
          >
            <p
              className={
                user.active ? " prospect-details" : "prospect-details-not-owned"
              }
              title={`${fullname(user.firstName, user.lastName)}`}
            >
              {`${fullname(user.firstName, user.lastName)}`}
            </p>
            <p className="prospect-titles">{`${user.email}`}</p>
          </FlexDiv>
          <div className="prospect-actions">
            <div
              className="pointer"
              onClick={() => setOpenDetail((prev) => !prev)}
            >
              <ArrowDropDownIcon
                sx={{ fontSize: "1.5rem", color: colors.black }}
                className="action-drop-icon"
                style={{ transform: openDetail ? "rotate(180deg)" : undefined }}
              />
            </div>
          </div>
        </FlexDiv>
        {openDetail ? <div className="divider" /> : null}
        <div className="prospect-container-bottom">
          <Collapse in={openDetail}>
            <UserInfo subscription={subscription} user={user} />
          </Collapse>
        </div>
      </ProspectWrapper>
    </>
  );
};

export default User;
