import { ENDPOINTS } from "config/endpoints";
import { useQuery } from "react-query";
import Error from "types/error";
import client from "utils/clients/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { User } from "types/user";

export default function useUserExists(
  subscriptionId: number,
  email: string | null,
  editingComplete: boolean
) {
  const params: any = {};

  params.subscriptionId = subscriptionId;

  if (email) {
    params.email = email.trim();
  }

  const queryString = new URLSearchParams(params).toString();

  const request: Request = {
    url: ENDPOINTS.user.exists + "?" + queryString,
    config: {
      method: "get",
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.user.exists, queryString],
    queryFn: () => client(request),
    enabled: email !== null && email.length > 0 && editingComplete,
  };

  const { data, ...rest } = useQuery<
    Response<{
      data?: User | null;
    }>,
    Error
  >(requestConfig);

  return { data, ...rest };
}
