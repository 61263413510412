import { FC, useEffect, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { SubscriptionsGridWrapper, PaginationWrapper } from "./styles";
import SubscriptionInfo from "./SubscriptionInfo";
import { FlexDiv } from "components/Layout/FlexDiv";
import { SubscriptionRowWrapper } from "./styles";
import { SubscriptionRowProps } from "./types";
import useSubscriptions from "hooks/services/subscription/useSubscriptions";
import { useSelectedSubscription } from "pages/home/context/subscription";
import { useSearchBar } from "context/searchbar";
import ReactPaginate from "react-paginate";
import { Constants } from "utils/constants/constants";
import { useNavigate } from "react-router-dom";
import PATH from "routing/path";
import SubscriptionFilter from "./SubscriptionFilter";
import SubscriptionStatus from "./SubscriptionStatus";

const SubscriptionRow: FC<SubscriptionRowProps> = ({
  selected,
  name,
  subscription,
}) => {
  return (
    <SubscriptionRowWrapper>
      <Grid container spacing={0} className="subscription-row">
        <Grid item xs={12} sm={12} md={3} lg={3} className="subscription-cell">
          <FlexDiv>
            <SubscriptionInfo
              selected={selected}
              name={name}
              subscription={subscription}
            />
          </FlexDiv>
        </Grid>
        <Grid item xs={12} sm={12} md={9} lg={9} className="subscription-cell">
          <SubscriptionStatus subscription={subscription} />
        </Grid>
      </Grid>
    </SubscriptionRowWrapper>
  );
};

const SubscriptionsGrid: FC = () => {
  const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);
  const [previousSearchBarText, setPreviousSearchBarText] = useState("");
  const navigate = useNavigate();

  const {
    subscription,
    searchParameters,
    setSearchParameters,
    selectedSubscriptionId,
    setSelectedSubscriptionId,
  } = useSelectedSubscription();

  const {
    data: subscriptions,
    isLoading: loadingSubscriptions,
    error: accountsError,
  } = useSubscriptions(searchParameters, itemOffset);

  const { searchBarText } = useSearchBar();

  useEffect(() => {
    if (
      subscriptions &&
      subscriptions.data?.data.length &&
      !selectedSubscriptionId
    ) {
      setSelectedSubscriptionId(subscriptions.data.data[0].id);
    }
  }, [subscriptions]);

  useEffect(() => {
    if (subscriptions?.data?.total) {
      const newPageCount = Math.ceil(
        subscriptions.data.total / Constants.defaultLimit
      );
      setPageCount(newPageCount);
    }

    if (
      !searchBarText &&
      subscriptions &&
      subscriptions.data &&
      subscriptions.data.data &&
      subscriptions.data.data.length >= 0
    ) {
      setPreviousSearchBarText("");
      setSearchParameters({ ...searchParameters, name: null });
      return;
    }

    if (searchBarText && subscriptions && subscriptions.data) {
      if (previousSearchBarText.length === 0) {
        setItemOffset(0);
      }
      setPreviousSearchBarText(searchBarText);
      setSearchParameters({ ...searchParameters, name: searchBarText });
      return;
    }
  }, [subscriptions, searchBarText]);

  if (!!accountsError) {
    if (accountsError?.response?.status === 401) {
      navigate(PATH.LOGIN);
    } else {
      throw new Error();
    }
  }

  const handlePageClick = (event: any) => {
    if (subscriptions?.data?.count) {
      const newOffset = event.selected * Constants.defaultLimit;
      setItemOffset(newOffset);
    }
  };

  return (
    <FlexDiv direction="column">
      <SubscriptionFilter
        onFiltersChange={(filters) =>
          setSearchParameters((prev) => {
            const {
              active,
              type,
              sortByExpirationDate,
              sortByLastLogin,
              ...rest
            } = prev;
            return { ...rest, ...filters };
          })
        }
      />
      {loadingSubscriptions ? (
        <FlexDiv>
          <CircularProgress />
        </FlexDiv>
      ) : (
        <SubscriptionsGridWrapper>
          {subscriptions?.data?.data?.length ? (
            subscriptions?.data?.data?.map((currAccount, idx) => (
              <div
                key={`subscription-${idx}`}
                onClick={() => setSelectedSubscriptionId(currAccount.id)}
              >
                <SubscriptionRow
                  selected={currAccount?.id === subscription?.id}
                  subscription={currAccount}
                  name={currAccount?.company.name || "No name"}
                />
              </div>
            ))
          ) : (
            <FlexDiv>
              No subscriptions found! Create a new subscription.
            </FlexDiv>
          )}
        </SubscriptionsGridWrapper>
      )}
      <PaginationWrapper>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          initialPage={itemOffset / Constants.defaultLimit}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
        />
      </PaginationWrapper>
    </FlexDiv>
  );
};

export default SubscriptionsGrid;
