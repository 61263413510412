import styled from "styled-components";
import { colors } from "styles/theme";

export const ProspectsWrapper = styled.div`
  width: 100%;
  height: calc(100% - 50px);

  .prospects-card-title {
    gap: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    .propspect-title {
      margin-bottom: 10px;
      margin-top: 0;
    }
  }

  .prospects {
    height: 100%;
    overflow-y: scroll;
  }

  .account-name {
    color: ${colors.blue};
  }

  .pointer {
    cursor: pointer;
  }

  .svg-prospect {
    border: solid 1px lightgrey;
    border-radius: 10px;
    padding: 2px;
    height: 25px;
    width: 25px;
  }
`;

export const ProspectWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 10px 0px;
  flex-direction: column;

  .Mui-checked.checkbox svg {
    color: ${colors.primary};
  }

  .prospect-avatar {
    background-color: ${colors.backgroundAvatar};
    color: ${colors.blue};
  } 

  .prospect-avatar-not-owned {
    background-color: ${colors.backgroundAvatarDisabled};
  } 

  .prospectInfo {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    width: 100%; 

    p {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
  }

  .prospect-container-top {
    position: relative;
  }

  .prospect-details {
    flex-grow: 1;
    margin: auto 10px;
  }

  .prospect-details-not-owned {
    flex-grow: 1;
    margin: auto 10px;
    color: ${colors.grey};
  }

  .prospect-titles {
    flex-grow: 1;
    margin: auto 10px;
    font-size: 0.8rem;
  }

  .prospect-actions {
    display: flex;
    justify-content: space-between;
    margin-left: 5px;
    gap: 8px;
  }

  .prospect-container-bottom {
    padding-left: 1rem;
  }

  .prospect-close-icon {
    position: absolute;
    top: 5px;
    right: 4px;
  }

  .prospect-drop-icon {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  .divider {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 0;
    margin: 0;
  }
`;

export const ProspectInfoWrapper = styled.div`
  width: 100%;
  height: 100%;

  .card-content {
    height: calc(100% - 50px);
    overflow-y: auto;
    padding-right: 10px;
  }

  .prospectInfo-card-title {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .prospect-info-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    gap: 30px;
  }

  .prospect-info-icon {
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
  }

  .prospect-alert-icon {
    margin-right: 10px;
    margin-left: 10px;
  }

  .prospect-info-row p {
    font-weight: 600;
    width: 130px;
  }
`;
