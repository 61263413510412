import AuthProvider, { useAuth } from "context/auth";
import AppRoutes from "routing/AppRoutes";
import AppRouter from "routing/AppRouter";
import { ErrorBoundary } from "react-error-boundary";
import CssBaseline from "@mui/material/CssBaseline";
import FullpageErroFallBack from "components/ErrorFallbacks/FullpageErrorFallback";
import PATH from "routing/path";
import { ThemeProvider } from "@mui/material";
import { theme } from "../styles/theme";
import MobileTopbar from "components/Navs/MobileTopBar";
import Topbar from "components/Navs/Topbar";
import { Hidden } from "@mui/material";
import SnackbarProvider from "context/snackbar";
import { QueryClient, QueryClientProvider } from "react-query";
import ConfirmModalProvider from "context/confirmModal";
import { ReactQueryDevtools } from "react-query/devtools";
import AnimatedRoutes from "components/router-animation/AnimatedRoutes";
import { FC } from "react";
import type { BrowserHistory } from "history";
import { GoogleOAuthProvider } from "@react-oauth/google";
import SearchbarProvider from "context/searchbar";
import SubscriptionProvider from "pages/home/context/subscription";

const queryClient = new QueryClient();

const App: FC<{ history: BrowserHistory }> = ({ history }) => {
  const onResetErrorBoundary = () => {
    //refresh all state and redirect to homepage
    localStorage.clear();
    window.location.replace(PATH.LOGIN);
  };

  return (
    <ErrorBoundary
      FallbackComponent={FullpageErroFallBack}
      onReset={onResetErrorBoundary}
    >
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""}
          >
            <SnackbarProvider>
              <ConfirmModalProvider>
                <div className="App">
                  <SubscriptionProvider>
                    <AppRouter history={history}>
                      <SearchbarProvider>
                        <AuthProvider>
                          <Hidden mdDown>
                            <Topbar />
                          </Hidden>
                          <Hidden mdUp>
                            <MobileTopbar />
                          </Hidden>
                          <AnimatedRoutes>
                            <AppRoutes />
                          </AnimatedRoutes>
                        </AuthProvider>
                      </SearchbarProvider>
                    </AppRouter>
                  </SubscriptionProvider>
                </div>
                <ReactQueryDevtools />
              </ConfirmModalProvider>
            </SnackbarProvider>
          </GoogleOAuthProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
