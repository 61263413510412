import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/clients/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { UpdatePasswordInputs } from "types/updatePassword";

export default function useUpdatePassword() {
  const updatePassword = ({ email, password, newPassword } : UpdatePasswordInputs) => {
    const request: Request = {
      url: ENDPOINTS.auth.password,
      config: {
        method: "put",
        data: { email, password, newPassword },
      },
    };

    return client(request);
  };

  const { isSuccess, isError, mutate, ...rest } = useMutation<Response<null>, Error, UpdatePasswordInputs>(
    updatePassword,
    {}
  );

  return { isSuccess, isError, mutate, ...rest };
}
