import styled from "styled-components";
import { colors } from "styles/theme";

export const TopbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colors.primary};
  width: 100%;
  height: 50px;
  padding: 0px 20px;

  .logo {
    flex: 1.5;
  }
  .search-wrapper {
    flex: 6;
    position: relative;
    margin-left: 30px;
  }

  .logo .top-bar-icon {
    height: 40px;
  }

  .top-bar-icon-light {
    color: ${colors.lightYellow}
  }

  .search-wrapper .search {
    width: 300px;
    height: 30px;
    background-color: ${colors.white};
    border: 0px !important;
    border-radius: 15px;
    padding: 0px 30px;
  }

  .search-wrapper .search-icon {
    position: absolute;
    color: ${colors.mediumGrey};
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
  }

  .user-info {
    white-space: nowrap;
    margin-left: 20px;
  }

  .top-bar-icon {
    cursor: pointer;
  }

  .user-info,
  .settings,
  .reminders,
  .status-hints,
  .logout {
    color: ${colors.white};
    flex: 0.5;
    flex-basis: 5%;
  } 
  
  .add-new {
    white-space: nowrap;
    color: ${colors.white};
    flex: auto;
  }

  .status-wrapper {
    flex: 1;
  }
  .status-wrapper .option {
    cursor: pointer;
  }

  .add-tooltip-action {
    padding: 10px;
    margin-top: 50px !important;
  }
`;

export const tooltipStyles = {
  tooltip: {
    color: colors.black,
    backgroundColor: colors.lightGrey,
    border: `2px solid ${colors.primary}`,
    borderRadius: "5px",
    padding: "10px 25px",
    fontSize: "0.9em",
  },
  arrow: {
    "&:before": {
      backgroundColor: colors.primary,
    },
  },
};
