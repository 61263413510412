import React, { SetStateAction } from "react";
import { Subscription } from "types/subscription";
import { UserAdd } from "types/userInsert";
import * as Yup from "yup";

export const validationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  userRoles: Yup.array()
    .min(1)
    .of(Yup.string().oneOf(["Basic", "Manager"])),
  subscriptionId: Yup.number().required("Subscription is required"),
});

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  userRoles: ["Basic"],
  subscriptionId: 0,
};

export interface InsertUserProps {
  subscription: Subscription;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  handleBack?: () => void;
}
