import styled from "styled-components";
import Page from "components/Layout/Page";
import { colors } from "styles/theme";

export const LoginWrapper = styled(Page)`
  background-color: ${colors.lightGrey};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px;

  .login-card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: ${colors.white};
    padding: 60px 40px;
    border-radius: 20px;
  }

  .login-text h2 {
    font-weight: 500;
  }

  .login-text {
    text-align: center;
    margin-bottom: 40px;
  }

  .login-form {
    padding: 20px;
  }

  .form-field {
    margin-bottom: 20px;
  }

  .logo {
    max-width: 75%;
  }

  @media screen and (max-width: 768px) {
    .login-form {
      padding: 20px 0px;
    }
  }
`;
