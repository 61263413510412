import { CircularProgress } from "@mui/material";
import { FlexDiv } from "components/Layout/FlexDiv";
import { useAuth } from "context/auth";
import { FC, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PATH from "./path";

const NotFoundHandler: FC = () => {
  const { admin, isLoading } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!admin) {
      navigate(`${PATH.LOGIN}?returnUrl=${pathname}`);
    }
  }, [admin]);

  if (isLoading) {
    return (
      <FlexDiv>
        <CircularProgress />
      </FlexDiv>
    );
  }

  if (admin) {
    return (
      <FlexDiv direction="column">
        <h2>404</h2>
        <h3>Page not found</h3>
      </FlexDiv>
    );
  }

  return <></>;
};

export default NotFoundHandler;
