import styled from "styled-components";
import Switch from "@mui/material/Switch";
import { colors } from "styles/theme";

export const SubscriptionInfoWrapper = styled.div`
  width: 100%;
  height: 100%;

  .card-content {
    height: calc(100% - 5rem);
    overflow-y: auto;
    padding-right: 10px;
  }

  .accountInfo-card-title {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .account-info {
    margin-top: 0px !important;
  }

  .svg-account {
    border: solid 1px lightgrey;
    border-radius: 10px;
    padding: 2px;
    height: 25px;
    width: 25px;
  }

  .account-info-icon {
    margin-right: 10px;
    margin-left: 10px;
    cursor: pointer;
  }

  .disabled-icon {
    color: ${colors.red};
    cursor: pointer;
  }

  .account-info-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    gap: 30px;
  }

  .account-info-row p {
    font-weight: 600;
    width: 130px;
  }

  .account-info-select {
    width: 100%;
    align-items: center;
    background-color: ${colors.lightGrey};
    height: 36px;
    border-radius: 13px;
    font-family: Poppins;
    text-color: ${colors.black};
    font-size: 12px;
    border: 0px !important;
  }

  .add-credits-button {
    background: ${colors.primary};
    color: ${colors.white};
    :hover {
      background: ${colors.primary}88;
    }
  }
  .account-name {
    color: ${colors.blue};
  }

  .input-date {
    width: 160px;
    background-color: ${colors.white};
    border-radius: 10px;
  }

  .input-date div {
    border-radius: 10px;
  }

  .pointer {
    cursor: pointer;
  }
`;

export const AddCreditWrapper = styled.div`
  background: #000000aa;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  .container {
    width: 500px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${colors.white};
    gap: 16px;
    .submit-add-button {
      border-radius: 8px;
      color: ${colors.white};
      text-transform: none;
    }
  }
`;
export const ActiveSwitch = styled(Switch).attrs(() => ({
  classes: {
    root: "root",
    switchBase: "switchBase",
    thumb: "thumb",
    track: "track",
    checked: "checked",
    focusVisible: "focusVisible",
  },
  disableRipple: true,
  focusVisibleClassName: "focusVisible",
}))`
  &.root {
    width: 52px;
    height: 26px;
    padding: 0;
    margin: 8px;
  }

  .switchBase {
    padding: 1px;

    &.checked {
      transform: translateX(26px);
      color: ${colors.primary};
      & + .track {
        background-color: #f8f9fd;
        opacity: 1;
        border: 2px solid ${colors.primary};
      }
    }

    &.focusVisible &.thumb {
      color: #f8f9fd;
      border: 6px solid #fff;
    }
  }

  .thumb {
    width: 24px;
    height: 24px;
  }

  & .track {
    border-radius: 13px;
    border: 2px solid ${colors.lightGrey};
    background-color: white;
    opacity: 1;
  }

  .checked {
  }
  .focusVisible {
  }
`;
