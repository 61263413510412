import styled from "styled-components";
import { colors } from "styles/theme";
import { CardProps } from "./types";

export const Card = styled.div<CardProps>`
  display: flex;
  background: ${colors.white};
  border-radius: 5px;
  margin: 10px;
  width: 100%;
  padding: ${(props) => props.padding || "20px"};
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  height: 100%;
  max-height: ${(props) => props.maxHeight || "unset"};
`;
