import { animated, useSpring } from "@react-spring/web";
import React, { FC } from "react";
import { onMountAnimationMakerArgs, onMountAnimation } from "./types";

const useOnMountAnimationMaker = (args: onMountAnimationMakerArgs) => {
  const { loop, reverse, duration, animation, delay, ...rest } = args;

  const useOnMountAnimation: FC<{ children: React.ReactNode }> = ({
    children,
  }) => {
    const animationConfig = {
      ...(loop && { loop: true }),
      ...(reverse && { reverse: true }),
      ...(delay && { delay }),
      ...animation,
    };

    const styles = useSpring({
      ...animationConfig,
      config: { duration: duration },
    });

    return (
      <animated.div style={styles} className="animated-container">
        {children}
      </animated.div>
    );
  };

  return useOnMountAnimation;
};

export default useOnMountAnimationMaker;
