import { createContext, FC, useContext, useEffect } from "react";
import { AuthContextType } from "./types";
import { Credentials } from "types/credentials";
import client from "utils/clients/client";
import { ENDPOINTS } from "config/endpoints";
import { Request } from "types/request";
import { useAsync } from "hooks/useAsync";
import { ADMIN_TOKEN_KEY } from "config/localStorage";
import Cookies from "universal-cookie";

import PATH from "routing/path";
import { Response } from "types/response";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { Constants } from "utils/constants/constants";
import { Admin } from "types/admin";
import { isTokenValid } from "utils";

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);
AuthContext.displayName = "AdminContext";

const AuthProvider: FC = ({ children }) => {
  const {
    data: admin,
    error,
    isLoading,
    isError,
    setData,
    run,
    reset,
  } = useAsync<Admin>();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const cookies = new Cookies();

  function setUserTokens(admin: Admin) {
    localStorage.setItem(ADMIN_TOKEN_KEY, admin.token.token);
    let cookieDomain = encodeURI(process.env.REACT_APP_FE_URL as string);
    // remove https://
    cookieDomain = cookieDomain.replace("https://", "");

    cookies.set(Constants.cronoAdminSessionCookie, admin.token.token, {
      domain: cookieDomain,
      path: "/",
      expires: new Date(admin.token.validTo),
      sameSite: "none",
      secure: true,
    });
  }

  const login = ({ email, password }: Credentials) => {
    const request: Request = {
      url: ENDPOINTS.auth.login,
      config: {
        method: "post",
        data: { email, password },
      },
    };

    run(
      client(request).then((result: Response<{ data: Admin }>) => {
        if (result.data) {
          const user = result.data.data;
          setUserTokens(user);
          return user;
        }
      })
    );
  };

  const logout = () => {
    setData(null);
    localStorage.removeItem(ADMIN_TOKEN_KEY);
    cookies.remove(Constants.cronoAdminSessionCookie);
    queryClient.clear();
    navigate(PATH.LOGIN);
  };

  const useAuthGuard = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      if (admin?.token && !isTokenValid(admin.token)) {
        logout();
      } else if (!admin) {
        navigate(
          `${PATH.LOGIN}?returnUrl=${location.pathname}?${location.search}`
        );
      }
    }, [admin]);
  };

  const value = {
    admin,
    error,
    isLoading,
    isError,
    cookies,
    login,
    logout,
    useAuthGuard,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
}
