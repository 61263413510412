import styled from "styled-components";

export const HomeWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 800px;

  .layout-grid {
    width: 100%;
    height: 100%;
    min-height: 800px;
  }

  .layout-grid-item {
    height: 50%;
  }

  .tabs-item {
    padding: 0px !important;
  }

  .notes-activities-multi-tab-card > div {
    height: 100%;
  }
`;
