import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/clients/client";
import { Request } from "types/request";
import { Response } from "types/response";
import {
  SubscriptionBucketInputs,
  SubscriptionInfoInputs,
} from "pages/home/SubscriptionInfo/model";

export type CreditsType = "Email" | "Phone" | "Template";

export default function usePostSubscriptionBucket() {
  const queryClient = useQueryClient();

  const editSubscription = (
    subscriptionBucketInfo: SubscriptionBucketInputs
  ) => {
    const request: Request = {
      url: `${ENDPOINTS.subscription.bucket}`,
      config: {
        method: "post",
        data: { data: subscriptionBucketInfo },
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    Error,
    SubscriptionBucketInputs
  >(editSubscription, {
    onSettled: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.toString().startsWith(ENDPOINTS.subscription.main),
      });
      queryClient.invalidateQueries([ENDPOINTS.subscription.main]);
    },
  });

  return { data, mutate, ...rest };
}
