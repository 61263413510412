import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/clients/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { SubscriptionInfoInputs } from "pages/home/SubscriptionInfo/model";

export default function useEditSubscription() {
  const queryClient = useQueryClient();

  const editSubscription = (subscriptionInfo: SubscriptionInfoInputs) => {
    const request: Request = {
      url: `${ENDPOINTS.subscription.main}`,
      config: {
        method: "patch",
        data: subscriptionInfo,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    Error,
    SubscriptionInfoInputs
  >(editSubscription, {
    onSettled: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.toString().startsWith(ENDPOINTS.subscription.main),
      });
      queryClient.invalidateQueries([ENDPOINTS.subscription.main]);
    },
  });

  return { data, mutate, ...rest };
}
