import Page from "components/Layout/Page";
import styled from "styled-components";
import { colors } from "styles/theme";

export const SettingsWrapper = styled.div`
  background-color: ${colors.lightGrey};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px;

  .general-wrapper .MuiBox-root {
    padding: 0;
  }

  .MuiGrid-item > div > .MuiBox-root {
    padding: 0;
  }

  .tab-content-container {
    max-height: 100%;
  }

  .settings-card {
    overflow-x: auto;
    align-items: start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;
    background: ${colors.white};
    padding: 40px;
    border-radius: 20px;
    max-width: 100%;
    width: 1200px;
  }

  .settings-tab {
    border-bottom: 3px solid ${colors.background};
  }

  @media (min-width: 600px) {
    .tabs-container {
      border-right: 3px solid ${colors.background};
    }
  }

  .settings-grid {
  }
`;

export const GeneralWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: wrap;

  .form-control {
    flex-basis: 100%;
    margin: 15px auto;
  }

  .form-control label {
    display: block;
    margin-bottom: 10px;
  }

  .form-control Input {
    display: block;
    width: 100%;
    height: 10px;
  }

  .mailer-button-container {
    height: 50px;
    display: flex;
    align-items: center;
  }

  .template-button-container {
    height: 50px;
    display: flex;
    align-items: center;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: start;
    margin-top: 15px;
  }

  .mail-button {
    width: 180px;
    height: 40px;
    font-size: 12px;
    margin: 10px;
    padding: 10px;
  }
  
  .template-button {
    width: 100px;
    height: 40px;
    font-size: 12px;
    margin: 10px;
    padding: 10px;
  }

  .select {
    width: 120px;
    background-color: ${colors.white};
    border-radius: 10px;
  }

  .variable-button {
    width: 80px;
    height: 40px;
    font-size: 12px;
    margin-top: 5px;
    margin-right: 20px; 
  }

  .success-message {
    color: ${colors.primary};
  }

  .not-success-message {
    color: ${colors.red};
  }

  .connect-card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: ${colors.white};
    padding-left: 60px;
    border-radius: 20px;
  }

  .password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .user-form {
  }

  .connect-text {
    text-align: center;
  }

  .connect-text h2 {
    font-size: 2rem;
  }

  .connect-text p {
    color: ${colors.primary};
  }

  .providers {
    margin: 20px 0px;
  }

  .provider-box {
    background: #f8f9fd;
    margin: 30px 0px;
    padding: 15px 40px;
    height: 100px;
    border: 1px solid #c4c4c4;
    cursor: pointer;
  }

  .provider-box img {
    flex-grow: 1;
  }

  .provider-box h3 {
    flex-grow: 2;
    margin: 0px 40px;
    font-weight: 500;
  }

  .linkedin-title {
    display: flex;
    font-size: 1rem;
    font-weight: 600;
    margin-right: 20px;
  }

  .linkedin-title-container {
    width: 400px;
    margin-bottom: 10px;
  }

  .titles-list-container {
    min-height: 150px;
  }

  .max-titles-div {
    height: 75px;
  }

  .remove-button {
    background-color: ${colors.lightGrey};
  }

  @media screen and (max-width: 768px) {
    .connect-form {
      padding: 20px 0px;
    }
  }
`;
