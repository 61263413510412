import { Modal } from "@mui/material";
import styled from "styled-components";
import { colors } from "styles/theme";

export const ModalWrapper = styled(Modal)`
  .MuiGrid-item {
    width: 100%;
  }

  .modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    border: 3px solid ${colors.primary};
    border-radius: 20px;
    background-color: white;
    box-shadow: 24;
    max-height: 100%;
    overflow-y: auto;
  }

  .modal-header {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    background-color: white;
    border-radius: 20px;
    padding: 20px 40px 10px 40px;
  }

  .modal-header h1 {
    margin: 0;
    font-size: 36px;
    font-weight: 600;
  }

  .modal-body {
    max-height: calc(100% - 100px);
    overflow-y: auto;
    background-color: #fff;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 20px;
  }

  .close-modal-icon {
    color: ${colors.primary};
    font-size: 40px;
    cursor: pointer;
  }

  .error-p {
    color: #d32f2f;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
  }

  @media screen and (max-width: 768px) {
    .modal-box {
      width: 100% !important;
    }
  }
`;

export const ModalFormWrapper = styled.div`
  .prospect-info-row {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    border-bottom: solid 0.5px ${colors.lightGrey};
    padding: 5px;
  }

  .added-users {
    width: 300px;
  }

  .added-user {
    width: 300px;
  }

  .input-date {
    width: 190px;
    background-color: ${colors.lightGrey};
    margin-right: 110px;
  }

  .input-date div {
    border-radius: 15px;
    height: 36px;
    background-color: ${colors.lightGrey};
  }

  .account-info-select {
    width: 190px;
    align-items: center;
    background-color: ${colors.lightGrey};
    height: 36px;
    border-radius: 15px;
    font-family: Poppins;
    text-color: ${colors.black};
    font-size: 12px;
    border: 0px !important ${colors.lightGrey};
    margin-right: 110px;
  }

  .add-new-label {
    width: 150px;
    display: inline-block;
    font-weight: bold;
  }

  .add-new-field {
    width: 300px;
    display: inline;
  }

  login-field {
    width: 500px;
    display: inline;
  }

  .add-new-field-select {
    width: 300px;
    background-color: ${colors.lightGrey};
    height: 40px;
    border-radius: 20px;
  }

  .add-new-auto-complete {
    height: 40px;
    .MuiOutlinedInput-root {
      padding-left: 0 !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      height: 40px;
    }

    input {
      padding-left: 10px !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      height: 40px !important;
    }
  }

  .add-new-field-select fieldset {
    border: none;
  }

  .error-message {
    color: ${colors.red};
    font-size: 12px;
  }

  .add-new-field input {
    background-color: ${colors.lightGrey};
    height: 30px;
    border: 0px !important;
    border-radius: 20px;
    padding: 5px 10px;
    width: 100%;
  }

  .add-new-field textarea {
    height: 30px !important;
    padding-top: 10px !important;
    padding-bottom: 0px !important;
    padding-left: 1em;
  }

  .add-new-field fieldset {
    border: none;
  }

  .login-field input {
    background-color: ${colors.lightGrey};
    height: 30px;
    border: 0px !important;
    border-radius: 20px;
    padding: 5px 10px;
    width: 100%;
  }

  .login-field fieldset {
    border: none;
  }

  .add-new-field textarea {
    background-color: ${colors.lightGrey};
    height: 30px;
    border-radius: 20px;
    padding: 5px 10px;
  }

  .textarea-parent div:first-child {
    padding: 0px;
  }

  form {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .button-container {
    align-self: end;
    padding: 20px;
  }

  .prospect-button-container {
    display: flex;
    padding: 20px;
  }

  .modal-button {
    margin-left: 40px;
  }

  .scrape-button {
    margin-left: -80px;
  }
`;
