import styled from "styled-components";
import { colors } from "styles/theme";
import { Collapse } from "@mui/material";

export const MobileTopbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colors.primary};
  width: 100%;
  height: 60px;
  padding: 0px 20px;

  .mobile-search-wrapper {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .search-icon {
    cursor: pointer;
  }

  .collapsable-searchbar {
    position: absolute;
    width: 100%;
  }
`;

export const MobileSearchBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.primary};
  width: 100%;
  height: 60px;
  padding: 30px 20px;

  .search {
    width: 300px;
    height: 30px;
    background-color: ${colors.white};
    border: 0px !important;
    border-radius: 15px;
    padding: 0px 30px;
  }
`;

export const CollapsableSearchBar = styled(Collapse)`
  position: absolute;
  width: 100%;
  z-index: 3;
`;
