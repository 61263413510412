import styled from "@emotion/styled/macro";
import { FlexDivProps } from "./types";

export const FlexDiv = styled.div<FlexDivProps>`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: ${(props) => props.alignItems || "center"};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "100%"};
`;
