import styled from "styled-components";
import { colors } from "styles/theme";
import { ChipInputProps } from "./types";

export const ChipInput = styled.input<ChipInputProps>`
  background-color: ${(props) =>
    props.background || colors.lightGrey} !important;
  max-width: ${(props) => props.maxWidth || "unset"};
  text-align: ${(props) => props.textAlign || "center"};
  height: 30px;
  border: 0px !important;
  border-radius: 20px;
  padding: 5px 10px;
  width: 100%;
`;
