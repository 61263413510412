import React, { useMemo, useState } from "react";
import { AddCreditWrapper } from "../styles";
import { Button, TextField, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { ChipInput } from "components/ChipInput";
import { useAuth } from "context/auth";
import { Constants } from "utils/constants/constants";

interface IProps {
  close: () => void;
  onSubmit: (value: string[]) => void;
  initialDomains: string[] | null;
}

const EditDomainsModal = ({ close, onSubmit, initialDomains }: IProps) => {
  const { admin } = useAuth();

  const [newDomainValue, setNewDomainValue] = useState<string>("");
  const [domains, setDomains] = useState<string[]>(initialDomains ?? []);

  const handleAddDomain = () => {
    if (newDomainValue) {
      setDomains([...domains, newDomainValue]);
      setNewDomainValue("");
    }
  };

  const allGood = useMemo(() => {
    return domains.every((domain) => domain.match(Constants.domainRegex));
  }, [domains]);

  return (
    <AddCreditWrapper id="add-credit-wrapper" onClick={close}>
      <div
        className="container"
        onClick={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
        }}
        style={{
          gap: 12,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography fontSize={24} lineHeight={"30px"} fontWeight={700}>
            Edit subscription domains
          </Typography>
          <Close
            style={{
              cursor: "pointer",
            }}
            onClick={close}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 4,
          }}
        >
          <ChipInput
            className="account-info-chip"
            textAlign={"center"}
            id="name"
            name="newDomainValue"
            value={newDomainValue}
            type="text"
            onChange={(event) => setNewDomainValue(event.target.value)}
            disabled={!admin?.canModify}
            placeholder="Insert new domain..."
            autoComplete="off"
          />
          <Button variant="contained" onClick={handleAddDomain}>
            Add
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          {domains.map((domain, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <TextField
                  className="account-info-chip"
                  id={`domain-${index}`}
                  name={`domain-${index}`}
                  value={domain}
                  type="text"
                  disabled={!admin?.canModify}
                  key={index}
                  placeholder="Customize domain..."
                  onChange={(event) => {
                    const newDomains = [...domains];
                    newDomains[index] = event.target.value;
                    setDomains(newDomains);
                  }}
                  error={!domain.match(Constants.domainRegex)}
                  helperText={
                    !domain.match(Constants.domainRegex) &&
                    "Invalid domain format"
                  }
                  autoComplete="off"
                  fullWidth
                  variant="standard"
                />
                <Close
                  onClick={() => {
                    const newDomains = [...domains];
                    newDomains.splice(index, 1);
                    setDomains(newDomains);
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </div>
            );
          })}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              onSubmit(domains);
            }}
            disabled={!allGood}
          >
            Save
          </Button>
        </div>
      </div>
    </AddCreditWrapper>
  );
};

export default EditDomainsModal;
