import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/clients/client";
import { Request } from "types/request";
import { Response } from "types/response";

export interface SubscriptionDelete {
  subscriptionId: number;
}

export default function useDeleteUser() {
  const queryClient = useQueryClient();

  const deleteUser = (subscriptionDelete: SubscriptionDelete) => {
    const request: Request = {
      url: `${ENDPOINTS.subscription.main}`,
      config: {
        method: "delete",
        data: subscriptionDelete,
      },
    };
    return client(request);
  };

  return useMutation<Response<null>, Error, SubscriptionDelete>(deleteUser, {
    onSettled: (result, error, variables) => {
      queryClient.invalidateQueries([ENDPOINTS.subscription.main]);
    },
  });
}
