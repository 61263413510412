import { CronoButton } from "components/CronoButton";
import { Input } from "components/FormComponents";
import { useAuth } from "context/auth";
import { useConditionalSnackBar } from "context/snackbar";
import { useFormik } from "formik";
import useUpdatePassword from "hooks/services/admin/useUpdatePassword";
import { FC } from "react";
import { GeneralWrapper } from "../styles";
import { initialValues, PasswordInputs, validationSchema } from "./model";

const PasswordSettings: FC = () => {
  const { admin } = useAuth();

  const formik = useFormik<PasswordInputs>({
    initialValues: {
      ...initialValues,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (admin) {
        updatePassword({
          email: admin.email,
          password: values.oldPassword,
          newPassword: values.newPassword,
        });
      }
    },
  });

  const { isError, isSuccess, mutate: updatePassword } = useUpdatePassword();

  useConditionalSnackBar([
    {
      condition: !!isError,
      message: "Error during update password",
      severity: "error",
    },
    {
      condition: !!isSuccess,
      message: "Succesfully update password",
      severity: "success",
    },
  ]);

  return (
    <GeneralWrapper>
      <form onSubmit={formik.handleSubmit} className="password-form">
        <div className="form-control">
          <label>Old Password</label>
          <Input
            value={formik.values.oldPassword}
            type="password"
            onChange={(value) =>
              formik.setFieldValue("oldPassword", value.target.value)
            }
            error={
              formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
            }
            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
          />
        </div>

        <div className="form-control">
          <label>New Password</label>
          <Input
            value={formik.values.newPassword}
            type="password"
            onChange={(value) =>
              formik.setFieldValue("newPassword", value.target.value)
            }
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            helperText={formik.touched.newPassword && formik.errors.newPassword}
          />
        </div>

        <div className="form-control">
          <label>Confirm new password</label>
          <Input
            value={formik.values.confirmPassword}
            onChange={(value) =>
              formik.setFieldValue("confirmPassword", value.target.value)
            }
            type="password"
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
        </div>

        <CronoButton
          variant="contained"
          color="primary"
          className="mail-button"
          type="submit"
        >
          Save
        </CronoButton>
      </form>
    </GeneralWrapper>
  );
};

export default PasswordSettings;
