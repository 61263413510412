import { Card } from "components/Card";
import { FlexDiv } from "components/Layout/FlexDiv";
import { FC } from "react";
import { FallbackProps } from "react-error-boundary";
import Logo from "../../assets/images/logo_crono.png";
import { FullPageErrorFallback } from "./styles";
import { colors } from "styles/theme";
import { CronoButton } from "components/CronoButton";

const FullpageErroFallBack: FC<FallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  return (
    <FullPageErrorFallback>
      <div>
        <Card>
          <FlexDiv direction="column">
            <img src={Logo} className="logo" alt="Crono Logo" />
            <span className="error-message">Ops! Something went wrong ...</span>
            <CronoButton onClick={() => resetErrorBoundary()}>
              RETURN TO LOGIN
            </CronoButton>
          </FlexDiv>
        </Card>
      </div>
    </FullPageErrorFallback>
  );
};

export default FullpageErroFallBack;
