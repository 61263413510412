import { ENDPOINTS } from "config/endpoints";
import { useQuery } from "react-query";
import Error from "types/error";
import client from "utils/clients/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { Subscription } from "types/subscription";
import { SubscriptionSearchParameters } from "types/subscriptionSearchParameters";

export default function useSubscriptions(
  searchParameters: SubscriptionSearchParameters,
  offset = 0
) {
  // get key value object from searchParameters
  const params: any = {
    limit: searchParameters.limit,
    offset,
  };

  if (searchParameters.name) {
    params.name = searchParameters.name;
  }

  if (searchParameters.active) {
    params.active = searchParameters.active;
  } else {
    params.active = false;
  }

  if (searchParameters.type) {
    params.type = searchParameters.type;
  }

  if (searchParameters.sortByExpirationDate) {
    params.sortByExpirationDate = searchParameters.sortByExpirationDate;
    params.sortByLastLogin = false;
  } else if (searchParameters.sortByLastLogin) {
    params.sortByLastLogin = searchParameters.sortByLastLogin;
    params.sortByExpirationDate = false;
  } else {
    params.sortByLastLogin = false;
    params.sortByExpirationDate = false;
  }

  const queryString = new URLSearchParams(params).toString();

  const request: Request = {
    url: ENDPOINTS.subscription.main + "?" + queryString,
    config: {
      method: "get",
    },
  };

  const requestConfig = {
    queryKey: [ENDPOINTS.subscription.main, queryString],

    queryFn: () => client(request),
  };

  const { data, ...rest } = useQuery<
    Response<{
      data: Subscription[];
      total: number;
      count: number;
      limit: number;
      offset: number;
    }>,
    Error
  >(requestConfig);

  return { data, ...rest };
}
