import * as Yup from "yup";
import { Credentials } from "types/credentials";

export const validationSchema = Yup.object({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const initialValues: Inputs = {
  email: "",
  password: "",
};

export type Inputs = Credentials;
