import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/clients/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { SignupEnableInsert } from "types/signupEnableInsert";

export default function useSignupEnable() {
  const enableSignup = (signupEnableInput: SignupEnableInsert) => {
    const request: Request = {
      url: `${ENDPOINTS.subscription.signupEnable}`,
      config: {
        method: "post",
        data: signupEnableInput,
      },
    };
    return client(request);
  };

  return useMutation<Response<null>, Error, SignupEnableInsert>(enableSignup, {
    onSettled: () => {},
  });
}
