export const ENDPOINTS = {
  subscription: {
    main: "Subscription",
    otherSettings: "Subscription/OtherSettings/Admin",
    // algorithmSettings: "Subscription/AlgorithmSettings/Admin",
    exists: "Subscription/exists",
    users: "Subscription/Users",
    multiCustomerInvite: "Subscription/MultiCustomer/Invite",
    resetPassword: "Subscription/User/Password",
    signupEnable: "Subscription/Signup/Enable",
    bucket: "Subscription/Bucket",
  },
  auth: {
    login: "Auth/Admin/Login",
    // me: "Auth/Me",
    password: "Auth/Admin/Password",
  },
  user: {
    main: "User",
    exists: "User/exists",
  },
  scrape: {
    metrics: "Scrape/Metrics",
  },
};
