import { FC, useState } from "react";
import { AccountInfoWrapper } from "./styles";
import useScrapeMetrics from "hooks/services/scrape/useScrapeMetrics";
import {
  CircularProgress,
  MenuItem,
  Select,
  Switch,
  ToggleButton,
} from "@mui/material";

const ScrapeMetricsView: FC = () => {
  const [index, setIndex] = useState<number | null>(null);
  const [phoneIndex, setPhoneIndex] = useState<number | null>(null);
  const [since, setSince] = useState<Date | null>(null);
  const [showEmail, setShowEmail] = useState<boolean>(true);

  const { data, isLoading } = useScrapeMetrics(since);

  const calculateRemaining: (remaining?: number) => string = (remaining) => {
    if (!remaining) return "";
    return "(remaining " + remaining + ")";
  };

  const calculatePercentageOnTotal: (
    total: number,
    data: number[]
  ) => string = (total, data) => {
    let totalScrape = 0;
    data.forEach((d) => {
      totalScrape += d;
    });
    if (totalScrape === 0) return "(0%)";
    return (
      "/" + totalScrape + " (" + ((total / totalScrape) * 100).toFixed(2) + "%)"
    );
  };

  const calculatePercentage: (value: number, total: number) => string = (
    valid,
    total
  ) => {
    if (total === 0) return "(0%)";
    return "(" + ((valid / total) * 100).toFixed(2) + "%)";
  };

  return showEmail ? (
    <AccountInfoWrapper>
      <h3 style={{ width: "100%" }}>
        Email Metrics
        {renderSelectDate()}
      </h3>
      <div className="scrollable-metrics-div">
        {isLoading && (
          <div>
            <CircularProgress />
          </div>
        )}
        {index !== null &&
          data?.data &&
          data.data?.data.emailMetrics[index] && (
            <div key={index}>
              <div className="add-new-label">Provider:</div>
              <div className="add-new-field">
                <b>
                  {data.data?.data.emailMetrics[index].provider.toUpperCase()}{" "}
                </b>
                {calculateRemaining(
                  data.data?.data.emailMetrics[index].scrapeCredits
                    ?.findRemaining
                )}
              </div>
              <br />
              <div className="add-new-label">Total Scrape:</div>
              <div className="add-new-field">
                {data.data?.data.emailMetrics[index].total}
                {calculatePercentageOnTotal(
                  data.data?.data.emailMetrics[index].total,
                  data.data?.data.emailMetrics.map((d) => d.total)
                )}
              </div>
              <br />
              <div className="add-new-label">Valid:</div>
              <div className="add-new-field">
                {data.data?.data.emailMetrics[index].valid}
                {"/"}
                {data.data?.data.emailMetrics[index].total}{" "}
                {calculatePercentage(
                  data.data?.data.emailMetrics[index].valid,
                  data.data?.data.emailMetrics[index].total
                )}
              </div>
              <br />
              <div className="add-new-label">Catch All:</div>
              <div className="add-new-field">
                {data.data?.data.emailMetrics[index].catchAll}
                {"/"}
                {data.data?.data.emailMetrics[index].total}{" "}
                {calculatePercentage(
                  data.data?.data.emailMetrics[index].catchAll,
                  data.data?.data.emailMetrics[index].total
                )}
              </div>
              <br />
              <div className="add-new-label">Invalid:</div>
              <div className="add-new-field">
                {data.data?.data.emailMetrics[index].invalid}
                {"/"}
                {data.data?.data.emailMetrics[index].total}{" "}
                {calculatePercentage(
                  data.data?.data.emailMetrics[index].invalid,
                  data.data?.data.emailMetrics[index].total
                )}
              </div>
              <br />
              <div className="add-new-label">Unknown:</div>
              <div className="add-new-field">
                {data.data?.data.emailMetrics[index].unknown}
                {"/"}
                {data.data?.data.emailMetrics[index].total}{" "}
                {calculatePercentage(
                  data.data?.data.emailMetrics[index].unknown,
                  data.data?.data.emailMetrics[index].total
                )}
              </div>
              <br />
              <div className="add-new-label">Bounced:</div>
              <div className="add-new-field">
                {data.data?.data.emailMetrics[index].bounced}
                {"/"}
                {data.data?.data.emailMetrics[index].total}{" "}
                {calculatePercentage(
                  data.data?.data.emailMetrics[index].bounced,
                  data.data?.data.emailMetrics[index].total
                )}
              </div>
              <br />
              <div className="add-new-label">Bounced Valid:</div>
              <div className="add-new-field">
                {data.data?.data.emailMetrics[index].bouncedValid}
                {"/"}
                {data.data?.data.emailMetrics[index].valid}{" "}
                {calculatePercentage(
                  data.data?.data.emailMetrics[index].bouncedValid,
                  data.data?.data.emailMetrics[index].valid
                )}
              </div>
              <br />
              <div className="add-new-label">Bounced Catch All:</div>
              <div className="add-new-field">
                {data.data?.data.emailMetrics[index].bouncedCatchAll}
                {"/"}
                {data.data?.data.emailMetrics[index].catchAll}{" "}
                {calculatePercentage(
                  data.data?.data.emailMetrics[index].bouncedCatchAll,
                  data.data?.data.emailMetrics[index].catchAll
                )}
              </div>
              <br />
              <div className="add-new-label">Bounced Invalid:</div>
              <div className="add-new-field">
                {data.data?.data.emailMetrics[index].bouncedInvalid}
                {"/"}
                {data.data?.data.emailMetrics[index].invalid}{" "}
                {calculatePercentage(
                  data.data?.data.emailMetrics[index].bouncedInvalid,
                  data.data?.data.emailMetrics[index].invalid
                )}
              </div>
              <br />
              <div className="add-new-label">Bounced Unknown:</div>
              <div className="add-new-field">
                {data.data?.data.emailMetrics[index].bouncedUnknown}
                {"/"}
                {data.data?.data.emailMetrics[index].unknown}{" "}
                {calculatePercentage(
                  data.data?.data.emailMetrics[index].bouncedUnknown,
                  data.data?.data.emailMetrics[index].unknown
                )}
              </div>
              <br />
              <div className="add-new-label">Bounced Shielded:</div>
              <div className="add-new-field">
                {data.data?.data.emailMetrics[index].bouncedShielded}
                {"/"}
                {data.data?.data.emailMetrics[index].bounced}{" "}
                {calculatePercentage(
                  data.data?.data.emailMetrics[index].bouncedShielded,
                  data.data?.data.emailMetrics[index].bounced
                )}
              </div>
              <br />
              <div className="add-new-label">Bounced Not Shielded:</div>
              <div className="add-new-field">
                {data.data?.data.emailMetrics[index].bouncedNotShielded}
                {"/"}
                {data.data?.data.emailMetrics[index].bounced}{" "}
                {calculatePercentage(
                  data.data?.data.emailMetrics[index].bouncedNotShielded,
                  data.data?.data.emailMetrics[index].bounced
                )}
              </div>
            </div>
          )}
        {index === null && data?.data && data.data.data.emailScrapeResume && (
          <div>
            <div className="add-new-label">Found:</div>
            <div className="add-new-field">
              {data.data.data.emailScrapeResume.found} /{" "}
              {data.data.data.emailScrapeResume.total}{" "}
              {calculatePercentage(
                data.data.data.emailScrapeResume.found,
                data.data.data.emailScrapeResume.total
              )}
            </div>
            <br />
            <div className="add-new-label">Not Found:</div>
            <div className="add-new-field">
              {data.data.data.emailScrapeResume.notFound} /{" "}
              {data.data.data.emailScrapeResume.total}{" "}
              {calculatePercentage(
                data.data.data.emailScrapeResume.notFound,
                data.data.data.emailScrapeResume.total
              )}
            </div>
            <br />
            <div className="add-new-label">Shield Valid (%):</div>
            <div className="add-new-field">
              {data.data.data.emailScrapeResume.bounceShieldValid} /{" "}
              {data.data.data.emailScrapeResume.bounceShieldTested}{" "}
              {calculatePercentage(
                data.data.data.emailScrapeResume.bounceShieldValid,
                data.data.data.emailScrapeResume.bounceShieldTested
              )}
            </div>
            <br />
            <br />
            {data.data.data.emailScrapeResume.values.map((value, i) => (
              <div key={i}>
                <div className="add-new-label">
                  {value.provider.toUpperCase()}:
                </div>
                <div className="add-new-field">
                  {value.total}
                  {calculatePercentage(
                    value.total,
                    data.data!.data.emailScrapeResume.total
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="pagination">
        {data?.data?.data.emailMetrics.map((_, i) => (
          <button
            key={i}
            onClick={() => setIndex(i)}
            className={index === i ? "active" : ""}
          >
            {i + 1}
          </button>
        ))}
        <button
          onClick={() => setIndex(null)}
          className={index === null ? "active" : ""}
        >
          Resume
        </button>
        {renderSwitchEmailPhone()}
      </div>
    </AccountInfoWrapper>
  ) : (
    <AccountInfoWrapper>
      <h3 style={{ width: "100%" }}>
        Phone Metrics
        {renderSelectDate()}
      </h3>
      <div className="scrollable-metrics-div">
        {phoneIndex !== null &&
          data?.data &&
          data.data?.data.phoneMetrics[phoneIndex] && (
            <div key={phoneIndex}>
              <div className="add-new-label">Provider:</div>
              <div className="add-new-field">
                <b>
                  {data.data?.data.phoneMetrics[
                    phoneIndex
                  ].provider.toUpperCase()}{" "}
                </b>
                {calculateRemaining(
                  data.data?.data.phoneMetrics[phoneIndex].scrapeCredits
                    ?.findRemaining
                )}
              </div>
              <br />
              <div className="add-new-label">Total Scrape:</div>
              <div className="add-new-field">
                {data.data?.data.phoneMetrics[phoneIndex].total}
                {calculatePercentageOnTotal(
                  data.data?.data.phoneMetrics[phoneIndex].total,
                  data.data?.data.phoneMetrics.map((d) => d.total)
                )}
              </div>
              <br />
              <div className="add-new-label">Wrong Number:</div>
              <div className="add-new-field">
                {data.data?.data.phoneMetrics[phoneIndex].wrongNumber}
                {"/"}
                {data.data?.data.phoneMetrics[phoneIndex].total}{" "}
                {calculatePercentage(
                  data.data?.data.phoneMetrics[phoneIndex].wrongNumber,
                  data.data?.data.phoneMetrics[phoneIndex].total
                )}
              </div>
              <br />
            </div>
          )}
        {phoneIndex === null &&
          data?.data &&
          data.data.data.phoneScrapeResume && (
            <div>
              <div className="add-new-label">Found:</div>
              <div className="add-new-field">
                {data.data.data.phoneScrapeResume.found} /{" "}
                {data.data.data.phoneScrapeResume.total}{" "}
                {calculatePercentage(
                  data.data.data.phoneScrapeResume.found,
                  data.data.data.phoneScrapeResume.total
                )}
              </div>
              <br />
              <div className="add-new-label">Not Found:</div>
              <div className="add-new-field">
                {data.data.data.phoneScrapeResume.notFound} /{" "}
                {data.data.data.phoneScrapeResume.total}{" "}
                {calculatePercentage(
                  data.data.data.phoneScrapeResume.notFound,
                  data.data.data.phoneScrapeResume.total
                )}
              </div>
            </div>
          )}
      </div>
      <div className="pagination">
        {data?.data?.data.phoneMetrics.map((_, i) => (
          <button
            key={i}
            onClick={() => setPhoneIndex(i)}
            className={phoneIndex === i ? "active" : ""}
          >
            {i + 1}
          </button>
        ))}
        <button
          onClick={() => setPhoneIndex(null)}
          className={phoneIndex === null ? "active" : ""}
        >
          Resume
        </button>
        {renderSwitchEmailPhone()}
      </div>
    </AccountInfoWrapper>
  );

  function renderSwitchEmailPhone() {
    return (
      <ToggleButton
        value="check"
        selected={showEmail}
        size="small"
        style={{ marginLeft: "50px" }}
        onChange={() => setShowEmail(!showEmail)}
      >
        {showEmail ? "Phone" : "Email"}
      </ToggleButton>
    );
  }

  function renderSelectDate() {
    return (
      <Select
        className="select-small"
        onChange={(e) => {
          if (e.target.value === "lastWeek") {
            setSince(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
          } else if (e.target.value === "lastMonth") {
            setSince(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
          } else if (e.target.value === "lastQuarter") {
            setSince(new Date(Date.now() - 90 * 24 * 60 * 60 * 1000));
          } else if (e.target.value === "lastYear") {
            setSince(new Date(Date.now() - 365 * 24 * 60 * 60 * 1000));
          } else {
            setSince(null);
          }
        }}
      >
        <MenuItem value="all">All</MenuItem>
        <MenuItem value="lastWeek">Last Week</MenuItem>
        <MenuItem value="lastMonth">Last Month</MenuItem>
        <MenuItem value="lastQuarter">Last Quarter</MenuItem>
        <MenuItem value="lastYear">Last Year</MenuItem>
      </Select>
    );
  }
};

export default ScrapeMetricsView;
