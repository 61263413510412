import { Input } from "components/FormComponents";
import { useAuth } from "context/auth";
import { FC } from "react";
import { GeneralWrapper } from "../styles";

const General: FC = () => {
  const { admin } = useAuth();

  return (
    <GeneralWrapper>
      <div className="user-form">
        <div className="form-control">
          <label>Name</label>
          <Input value={admin?.username} disabled />
        </div>

        <div className="form-control">
          <label>E-Mail</label>
          <Input value={admin?.email} disabled />
        </div>
      </div>
    </GeneralWrapper>
  );
};

export default General;
