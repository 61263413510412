import { FlexDiv } from "components/Layout/FlexDiv";
import { FC } from "react";
import { ModalWrapper } from "./styles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SendSignupLinkForm from "pages/home/Subscriptions/sendSignupLink/sendSignupLinkForm";

interface NewSignupLinkModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddNewSignupLinkModal: FC<NewSignupLinkModalProps> = ({
  open,
  setOpen,
}) => {
  function handleClose() {
    setOpen(false);
  }

  return (
    <ModalWrapper
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-box">
        <FlexDiv
          justifyContent="space-between"
          alignItems="center"
          className="modal-header"
        >
          <h2>Send signup link</h2>
          <CancelOutlinedIcon
            className="close-modal-icon"
            onClick={handleClose}
          />
        </FlexDiv>
        <div>
          <SendSignupLinkForm setOpen={setOpen} />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AddNewSignupLinkModal;
