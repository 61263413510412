import { FC } from "react";
import { SubscriptionStatusWrapper } from "./styles";
import { FlexDiv } from "components/Layout/FlexDiv";
import { SubscriptionStatusProps } from "./types";
import moment from "moment";

const SubscriptionStatus: FC<SubscriptionStatusProps> = ({ subscription }) => {
  return (
    <SubscriptionStatusWrapper>
      <div className="controls-container">
        <FlexDiv className={`control red`}>
          {subscription.company.integrationType}
        </FlexDiv>
        <FlexDiv className={`control blue`}>
          {/* format date */}
          {moment(subscription.expirationDate).format("DD/MM/YYYY")}
        </FlexDiv>
        <FlexDiv
          className={`control light-blue`}
          style={{
            textAlign: "center",
          }}
        >
          Basic {subscription.enabledUsers.Basic} Managers:{" "}
          {subscription.enabledUsers.Manager}
        </FlexDiv>
        <FlexDiv className={`control yellow`}>
          {subscription.lastLogin
            ? moment(subscription.lastLogin).format("DD/MM/YYYY")
            : "-"}
        </FlexDiv>
        <FlexDiv className={`control green`}>
          {subscription.subscriptionType}
        </FlexDiv>
        <FlexDiv
          className={`control light-blue`}
          style={{
            textAlign: "center",
          }}
        >
          {subscription.active ? "Active" : "Inactive"}
        </FlexDiv>
      </div>
    </SubscriptionStatusWrapper>
  );
};

export default SubscriptionStatus;
