import { TextField } from "@mui/material";
import styled from "styled-components";
import { colors } from "styles/theme";

const Input = styled(TextField)`
  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.primary} !important;
  }

  & .Mui-focused .MuiInputLabel-root {
    color: ${colors.primary} !important;
  }
`;

export default Input;
