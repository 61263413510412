import styled from "styled-components";
import { colors } from "styles/theme";

export const SubscriptionDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .tab-element {
    border-radius: 5px 5px 0 0;
    padding-top: 20px;
    padding-left: 20px;
    font-size: 1.17em;
    cursor: pointer;
    font-weight: bold;
  }

  .tab-element-selected {
    background-color: ${colors.white};
  }

  .tab-user-name {
    color: ${colors.blue};
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiTabs-root {
    background-color: ${colors.background};
  }

  .MuiTabs-flexContainer {
    height: 100%;
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTabPanel-root {
    flex-grow: 1;
    overflow: hidden;
  }
`;
