import { FC } from "react";
import { useRoutes } from "react-router-dom";
import { Routes } from "./routes";

const AppRoutes: FC = () => {
  const routes = useRoutes(Routes);

  return routes;
};

export default AppRoutes;
