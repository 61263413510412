import { FlexDiv } from "components/Layout/FlexDiv";
import { FC, useState } from "react";
import { useSelectedSubscription } from "../context/subscription";
import User from "./User";
import { ProspectsWrapper } from "./styles";
import Add from "assets/images/add.png";
import AddNewUserModal from "components/Modal/AddNewUserModal";
import { useAuth } from "context/auth";

const Prospects: FC = () => {
  const { admin } = useAuth();
  const { subscription } = useSelectedSubscription();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  if (!subscription) {
    return (
      <FlexDiv>
        <span> Please select a subscription</span>
      </FlexDiv>
    );
  }

  return (
    <>
      <AddNewUserModal
        open={open}
        setOpen={setOpen}
        subscription={subscription}
      />
      <ProspectsWrapper>
        <FlexDiv
          height="unset"
          className="prospects-card-title"
          justifyContent="space-between"
          alignItems="start"
        >
          <h3 className="propspect-title">
            Users -{" "}
            <span className="account-name">{subscription.company.name}</span>
          </h3>
          <span className="prospect-add">
            {admin?.canCreate && (
              <img
                className="pointer"
                src={Add}
                alt="add"
                width="24px"
                onClick={() => handleOpenModal()}
              />
            )}
          </span>
        </FlexDiv>
        <div className="prospects">
          {subscription?.users?.map((user) => (
            <User key={user.id} user={user} subscription={subscription} />
          ))}
        </div>
      </ProspectsWrapper>
    </>
  );
};

export default Prospects;
