import { CircularProgress, MenuItem, Select, TextField } from "@mui/material";
import { CronoButton } from "components/CronoButton";
import { FlexDiv } from "components/Layout/FlexDiv";
import { ModalFormWrapper } from "components/Modal/styles";
import { useConditionalSnackBar } from "context/snackbar";
import { useFormik } from "formik";
import useCreateUser from "hooks/services/user/useCreateUser";
import useUserExists from "hooks/services/user/useUserExists";
import { FC, useState } from "react";
import { trimObject } from "utils/object";
import { initialValues, validationSchema, InsertUserProps } from "./model";
import { UserAdd } from "types/userInsert";
import { getError } from "utils";
import useInviteUserForMultiCustomerSubscription from "hooks/services/user/useInviteUserForMultiCustomerSubscription";

const AddUserForm: FC<InsertUserProps> = ({
  subscription,
  setOpen,
  handleBack,
}) => {
  const [userRoles, setUserRoles] = useState<string[]>(["Basic"]);
  const [editingCompleteUser, setEditingCompleteUser] = useState(false);

  const {
    isSuccess,
    isLoading,
    mutateAsync: createUser,
    error,
  } = useCreateUser();

  const {
    isSuccess: inviteUserSuccess,
    isLoading: inviteUserLoading,
    mutateAsync: inviteUserForMultiCustomerSubscription,
    error: inviteUserError,
  } = useInviteUserForMultiCustomerSubscription();

  const formik = useFormik<UserAdd>({
    initialValues: {
      ...initialValues,
      subscriptionId: subscription?.id ?? 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (userAlreadyExists()) return;

      const trimmed = trimObject(values);
      if (subscription) {
        if (!subscription.multiCustomer) {
          createUser({ ...trimmed, subscriptionId: subscription.id }).then(
            () => {
              setOpen(false);
            }
          );
          return;
        } else {
          inviteUserForMultiCustomerSubscription({ ...trimmed }).then(() => {
            setOpen(false);
          });
        }
      }
    },
  });

  const { data: existingUsers } = useUserExists(
    subscription?.id ?? 0,
    formik.values.email,
    editingCompleteUser
  );

  useConditionalSnackBar([
    {
      condition: !!error,
      message: getError(error) ?? "Error while creating user",
      severity: "error",
    },
    {
      condition: !!isSuccess,
      message: "User added succesfully",
      severity: "success",
    },
    {
      condition: !!inviteUserError,
      message:
        getError(inviteUserError) ??
        "Error while invite user for multicustomer subscription",
      severity: "error",
    },
    {
      condition: !!inviteUserSuccess,
      message: "User invited succesfully",
      severity: "success",
    },
  ]);

  function checkShowMessage() {
    if (!subscription) {
      return true;
    }

    if (userAlreadyExists()) return true;

    return false;
  }

  function userAlreadyExists() {
    if (existingUsers?.data?.data) {
      return true;
    }

    return false;
  }

  function getMessageText() {
    if (userAlreadyExists() && subscription) {
      return "This user already exists";
    }

    return "";
  }

  return (
    <ModalFormWrapper>
      <form onSubmit={formik.handleSubmit} className="prospect-form">
        <div className="prospect-info-row">
          <p className="add-new-label">First name</p>
          <TextField
            className="add-new-field"
            id="firstName"
            name="firstName"
            value={formik.values.firstName}
            onFocus={() => setEditingCompleteUser(false)}
            onBlur={() => setEditingCompleteUser(true)}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </div>
        <div className="prospect-info-row">
          <p className="add-new-label">Last name</p>
          <TextField
            className="add-new-field"
            id="lastName"
            name="lastName"
            value={formik.values.lastName}
            onFocus={() => setEditingCompleteUser(false)}
            onBlur={() => setEditingCompleteUser(true)}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </div>

        <div className="prospect-info-row">
          <p className="add-new-label">E-Mail</p>
          <TextField
            className="add-new-field"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onFocus={() => setEditingCompleteUser(false)}
            onBlur={() => setEditingCompleteUser(true)}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </div>
        <div className="prospect-info-row">
          <p className="add-new-label">Roles</p>
          <Select
            className="account-info-select"
            id="userRoles"
            name="userRoles"
            value={userRoles}
            onChange={(e) => {
              const { value } = e.target;
              // if value is array
              console.log(value);
              const roles = [];
              if (Array.isArray(value)) {
                setUserRoles(value);
                roles.push(...value);
              } else {
                setUserRoles([value]);
                roles.push(value);
              }
              formik.values.userRoles = roles;
            }}
            multiple
          >
            <MenuItem key="Basic" value={"Basic"}>
              Basic
            </MenuItem>
            <MenuItem key="Manager" value={"Manager"}>
              Manager
            </MenuItem>
          </Select>
        </div>
        {Boolean(formik.errors.userRoles) && (
          <p className="error-message">{formik.errors.userRoles}</p>
        )}
        {checkShowMessage() && (
          <div
            style={{
              padding: "20px",
              margin: "15px auto",
              backgroundColor: "#07c8c04f",
              borderRadius: "10px",
            }}
          >
            {getMessageText()}
          </div>
        )}
        <FlexDiv
          className="prospect-button-container"
          justifyContent="space-between"
        >
          {handleBack ? (
            <CronoButton className="mail-button" onClick={() => handleBack()}>
              Back
            </CronoButton>
          ) : (
            <div />
          )}
          {isLoading || inviteUserLoading ? (
            <CircularProgress />
          ) : (
            <CronoButton
              variant="contained"
              color="primary"
              className="mail-button"
              type="submit"
              disabled={userAlreadyExists()}
            >
              Insert
            </CronoButton>
          )}
        </FlexDiv>
      </form>
    </ModalFormWrapper>
  );
};

export default AddUserForm;
