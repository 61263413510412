import PATH from "routing/path";
import { Token } from "types/token";
import moment from "moment";
import { AccountStatus } from "types/enums/accountStatus";
import { Constants } from "./constants/constants";
import Error from "types/error";

export const fullname = (first?: string | null, last?: string | null) => {
  const firstname = first && first != null && first !== "null" ? first : "";
  const lastname = last && last != null && last !== "null" ? last : "";
  if (firstname === "" && lastname === "") return "";
  return `${firstname} ${lastname}`;
};

export function IsHomeRoute(route: string): boolean {
  return route === PATH.HOME;
}

export function formatTime(time: Date) {
  return time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
}

export function isTokenValid(token: Token) {
  return moment.utc(token.validTo).isAfter(moment().utc());
}

export function getStringFromStatus(status: AccountStatus) {
  switch (status) {
    case AccountStatus.INACTIVE:
      return "Not in target";
    case AccountStatus.NURTURE:
      return "Nurture";
    case AccountStatus.WORKING:
      return "Prospecting";
    case AccountStatus.CALL_SCHEDULED:
      return "Meeting";
    case AccountStatus.OPEN_OPPORTUNITY:
      return "Opportunity";
    case AccountStatus.CUSTOMER:
      return "Customer";
    default:
      return "";
  }
}

export function checkIfValidWebsite(url: string): boolean {
  const regex = Constants.websiteRegex;
  return regex.test(url);
}

export function extractDomainFromWebsite(url: string): string {
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    url = "https://" + url;
  }
  const u = new URL(url);
  return u.host.replace("www.", "");
}

export const getImageFromUrl = (url: string | null, size: number | null) => {
  if (!url) return Constants.defaultImageUrl;
  let domain = extractDomainFromWebsite(url);
  if (!domain) return Constants.defaultImageUrl;

  if (!size) size = 64;

  return `https://www.google.com/s2/favicons?sz=${size}&domain_url=${domain}`;
};

export function getError(error: Error | null) {
  return error?.response?.data?.errors && error.response.data.errors.length > 0
    ? error!.response.data.errors![0]!.message
    : error?.message;
}
