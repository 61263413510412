import { FlexDiv } from "components/Layout/FlexDiv";
import { FC } from "react";
import { ModalWrapper } from "./styles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AddSubscriptionForm from "pages/home/Subscriptions/addSubscription/addSubscriptionForm";

interface NewSubscriptionModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddNewSubscriptionModal: FC<NewSubscriptionModalProps> = ({
  open,
  setOpen,
}) => {
  function handleClose() {
    setOpen(false);
  }

  return (
    <ModalWrapper
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-box">
        <FlexDiv
          justifyContent="space-between"
          alignItems="center"
          className="modal-header"
        >
          <h2>Create new subscription</h2>
          <CancelOutlinedIcon
            className="close-modal-icon"
            onClick={handleClose}
          />
        </FlexDiv>
        <div>
          <AddSubscriptionForm setOpen={setOpen} />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AddNewSubscriptionModal;
