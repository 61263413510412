import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/clients/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { OtherSettingsInputs } from "pages/home/OtherSettingsInfo/model";

export default function useEditOtherSettings() {
  const queryClient = useQueryClient();

  const editOtherSettings = (otherSettingsInputs: OtherSettingsInputs) => {
    const request: Request = {
      url: `${ENDPOINTS.subscription.otherSettings}`,
      config: {
        method: "patch",
        data: otherSettingsInputs,
      },
    };
    return client(request);
  };

  const { data, mutate, ...rest } = useMutation<
    Response<null>,
    Error,
    OtherSettingsInputs
  >(editOtherSettings, {
    onSettled: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.toString().startsWith(ENDPOINTS.subscription.main),
      });
    },
  });

  return { data, mutate, ...rest };
}
