import { FC, useEffect, useState } from "react";
import { MenuItem, Select } from "@mui/material";
import { useSelectedSubscription } from "../context/subscription";
import { FlexDiv } from "components/Layout/FlexDiv";
import { OtherSettingsWrapper, ActiveSwitch } from "./styles";
import { OtherSettingsInputs } from "./model";
import { useConditionalSnackBar } from "context/snackbar";
import useEditOtherSettings from "hooks/services/subscription/useEditOtherSettings";
import { ChipInput } from "components/ChipInput";
import { AccountStatus } from "types/enums/accountStatus";
import { getStringFromStatus } from "utils";
import { useAuth } from "context/auth";
import { set } from "date-fns";

const OtherSettingsInfo: FC = () => {
  const { admin } = useAuth();
  const { subscription } = useSelectedSubscription();

  const [inputError, setInputError] = useState<string | null>(null);

  const [currency, setCurrency] = useState<string>("");
  const [hasLinkedin, setHasLinkedin] = useState<boolean>(false);
  const [hasEmail, setHasEmail] = useState<boolean>(false);
  const [hasCall, setHasCall] = useState<boolean>(false);
  const [monthScrapeRequest, setMonthScrapeRequest] = useState<number>(0);
  const [monthVerifyRequest, setMonthVerifyRequest] = useState<number>(0);
  const [monthFindPhoneRequest, setMonthFindPhoneRequest] = useState<number>(0);
  const [hasLastFeatures, setHasLastFeatures] = useState<boolean>(false);
  const [monthGenerateTemplateRequest, setMonthGenerateTemplateRequest] =
    useState<number>(0);
  const [hasOpportunities, setHasOpportunities] = useState<boolean>(false);

  useEffect(() => {
    setCurrency(subscription?.otherSettings?.currency || "");
    setMonthScrapeRequest(subscription?.otherSettings?.monthScrapeRequest || 0);
    setMonthVerifyRequest(subscription?.otherSettings?.monthVerifyRequest || 0);
    setMonthFindPhoneRequest(
      subscription?.otherSettings?.monthFindPhoneRequest || 0
    );
    setMonthGenerateTemplateRequest(
      subscription?.otherSettings?.monthGenerateTemplateRequest || 0
    );
    setHasLinkedin(subscription?.otherSettings?.hasLinkedin || false);
    setHasEmail(subscription?.otherSettings?.hasEmail || false);
    setHasCall(subscription?.otherSettings?.hasCall || false);
    setHasLastFeatures(subscription?.otherSettings?.hasLastFeatures || false);
    setInputError(null);
    setHasOpportunities(subscription?.otherSettings?.hasOpportunities || false);
  }, [subscription]);

  const {
    mutate: editOtherSettings,
    data: isEditSuccess,
    error: isEditError,
  } = useEditOtherSettings();

  useConditionalSnackBar([
    {
      condition: !!isEditError,
      message: "Error while update other settings",
      severity: "error",
    },
    {
      condition: !!inputError,
      message: inputError!,
      severity: "error",
    },
    {
      condition: !!isEditSuccess,
      message: "Other settings updated successfully",
      severity: "success",
    },
  ]);

  if (!subscription) {
    return (
      <FlexDiv>
        <span> Please select a subscription</span>
      </FlexDiv>
    );
  }

  const updateOtherSettings = (
    inputs: Omit<OtherSettingsInputs, "subscriptionId">
  ) => {
    editOtherSettings({ ...inputs, subscriptionId: subscription.id });
  };

  return (
    <OtherSettingsWrapper>
      <div className="card-content">
        <div className="account-info-row">
          <p>Currency</p>
          <Select
            className="account-info-select"
            id="currency"
            name="currency"
            value={currency}
            disabled={admin?.canModify ? false : true}
            onChange={(e) => {
              setCurrency(e.target.value as string);
              updateOtherSettings({
                currency: e.target.value as string,
              });
            }}
          >
            <MenuItem key="$" value={"$"}>
              $
            </MenuItem>
            <MenuItem key="€" value={"€"}>
              €
            </MenuItem>
          </Select>
        </div>
        <div className="account-info-row">
          <p>Linkedin Channel</p>
          <div>
            <ActiveSwitch
              id="hasLinkedin"
              name="hasLinkedin"
              checked={hasLinkedin}
              disabled={admin?.canModify ? false : true}
              onChange={(e) => {
                setHasLinkedin(e.target.checked);
                updateOtherSettings({
                  hasLinkedin: e.target.checked,
                });
              }}
            />
          </div>
        </div>
        <div className="account-info-row">
          <p>Email Channel</p>
          <div>
            <ActiveSwitch
              id="hasEmail"
              name="hasEmail"
              checked={hasEmail}
              disabled={admin?.canModify ? false : true}
              onChange={(e) => {
                setHasEmail(e.target.checked);
                updateOtherSettings({
                  hasEmail: e.target.checked,
                });
              }}
            />
          </div>
        </div>
        <div className="account-info-row">
          <p>Call Channel</p>
          <div>
            <ActiveSwitch
              id="hasCall"
              name="hasCall"
              checked={hasCall}
              disabled={admin?.canModify ? false : true}
              onChange={(e) => {
                setHasCall(e.target.checked);
                updateOtherSettings({
                  hasCall: e.target.checked,
                });
              }}
            />
          </div>
        </div>
        <div className="account-info-row">
          <p>Month Scrape Quantity</p>
          <ChipInput
            className="add-new-field"
            id="monthScrapeRequest"
            name="monthScrapeRequest"
            value={monthScrapeRequest}
            disabled={admin?.canModify ? false : true}
            onChange={(e: any) => {
              const value = e.target.value;
              if (isNaN(Number(value))) {
                setInputError("Please enter a number");
                return;
              }
              const numberValue = Number(value);

              setMonthScrapeRequest(numberValue);
            }}
            onBlur={() => {
              if (monthScrapeRequest || monthScrapeRequest === 0) {
                updateOtherSettings({
                  monthScrapeRequest: monthScrapeRequest,
                });
              }
            }}
          />
        </div>
        <div className="account-info-row">
          <p>Month Verify Quantity</p>
          <ChipInput
            className="add-new-field"
            id="monthVerifyRequest"
            name="monthVerifyRequest"
            value={monthVerifyRequest}
            disabled={admin?.canModify ? false : true}
            onChange={(e: any) => {
              const value = e.target.value;
              if (isNaN(Number(value))) {
                setInputError("Please enter a number");
                return;
              }
              const numberValue = Number(value);

              setMonthVerifyRequest(numberValue);
            }}
            onBlur={() => {
              if (monthVerifyRequest || monthVerifyRequest === 0) {
                updateOtherSettings({
                  monthVerifyRequest: monthVerifyRequest,
                });
              }
            }}
          />
        </div>
        <div className="account-info-row">
          <p>Month Find Phone Quantity</p>
          <ChipInput
            className="add-new-field"
            id="monthFindPhoneRequest"
            name="monthFindPhoneRequest"
            value={monthFindPhoneRequest}
            disabled={admin?.canModify ? false : true}
            onChange={(e: any) => {
              const value = e.target.value;
              if (isNaN(Number(value))) {
                setInputError("Please enter a number");
                return;
              }
              const numberValue = Number(value);

              setMonthFindPhoneRequest(numberValue);
            }}
            onBlur={() => {
              if (monthFindPhoneRequest || monthFindPhoneRequest === 0) {
                updateOtherSettings({
                  monthFindPhoneRequest: monthFindPhoneRequest,
                });
              }
            }}
          />
        </div>
        <div className="account-info-row">
          <p> Last Features</p>
          <div>
            <ActiveSwitch
              id="hasLastFeatures"
              name="hasLastFeatures"
              disabled={admin?.canModify ? false : true}
              checked={hasLastFeatures}
              onChange={(e) => {
                setHasLastFeatures(e.target.checked);
                updateOtherSettings({
                  hasLastFeatures: e.target.checked,
                });
              }}
            />
          </div>
        </div>
        <div className="account-info-row">
          <p>Month Generate Quantity</p>
          <ChipInput
            className="add-new-field"
            id="monthGenerateTemplateRequest"
            name="monthGenerateTemplateRequest"
            value={monthGenerateTemplateRequest}
            disabled={admin?.canModify ? false : true}
            onChange={(e: any) => {
              const value = e.target.value;
              if (isNaN(Number(value))) {
                setInputError("Please enter a number");
                return;
              }
              const numberValue = Number(value);

              setMonthGenerateTemplateRequest(numberValue);
            }}
            onBlur={() => {
              if (
                monthGenerateTemplateRequest ||
                monthGenerateTemplateRequest === 0
              ) {
                updateOtherSettings({
                  monthGenerateTemplateRequest: monthGenerateTemplateRequest,
                });
              }
            }}
          />
        </div>
        <div className="account-info-row">
          <p>Has Opportunities</p>
          <div>
            <ActiveSwitch
              id="hasOpportunities"
              name="hasOpportunities"
              checked={hasOpportunities}
              disabled={admin?.canModify ? false : true}
              onChange={(e) => {
                setHasOpportunities(e.target.checked);
                updateOtherSettings({
                  hasOpportunities: e.target.checked,
                });
              }}
            />
          </div>
        </div>
      </div>
    </OtherSettingsWrapper>
  );
};

export default OtherSettingsInfo;
