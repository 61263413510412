import { CircularProgress, Switch, TextField } from "@mui/material";
import { CronoButton } from "components/CronoButton";
import { FlexDiv } from "components/Layout/FlexDiv";
import { ModalFormWrapper } from "components/Modal/styles";
import { useConditionalSnackBar } from "context/snackbar";
import { useFormik } from "formik";
import { FC } from "react";
import { trimObject } from "utils/object";
import {
  initialValues,
  validationSchema,
  SendSignupLinkInputs,
  SendSignupLinkProps,
} from "./model";
import useSignupEnable from "hooks/services/subscription/useSignupEnable";
import { getError } from "utils";

const SendSignupLinkForm: FC<SendSignupLinkProps> = ({ setOpen }) => {
  const {
    isSuccess,
    isLoading,
    mutateAsync: enableSignup,
    error,
  } = useSignupEnable();

  const formik = useFormik<SendSignupLinkInputs>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const trimmed = trimObject(values);

      enableSignup({
        email: trimmed.email,
        onlyPay: !values.onlyPay,
      }).then(() => {
        setOpen(false);
      });
    },
  });
  useConditionalSnackBar([
    {
      condition: !!error,
      message: getError(error) ?? "Error while creating singup link",
      severity: "error",
    },
    {
      condition: !!isSuccess,
      message: "Signup link sent succesfully for: " + formik.values.email,
      severity: "success",
    },
  ]);

  return (
    <ModalFormWrapper>
      <form onSubmit={formik.handleSubmit} className="prospect-form">
        <h4>User Info</h4>
        <div className="prospect-info-row">
          <p className="add-new-label">Email</p>
          <TextField
            className="add-new-field"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </div>
        <div className="prospect-info-row">
          <p className="add-new-label">Free Trial</p>
          <Switch
            name="onlyPay"
            onChange={formik.handleChange}
            checked={formik.values.onlyPay}
          />
        </div>
        <div className="button-container">
          {isLoading ? (
            <FlexDiv>
              <CircularProgress />
            </FlexDiv>
          ) : (
            <CronoButton variant="contained" type="submit">
              Send
            </CronoButton>
          )}
        </div>
      </form>
    </ModalFormWrapper>
  );
};

export default SendSignupLinkForm;
