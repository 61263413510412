import { ENDPOINTS } from "config/endpoints";
import { useMutation, useQueryClient } from "react-query";
import Error from "types/error";
import client from "utils/clients/client";
import { Request } from "types/request";
import { Response } from "types/response";
import { UserAdd } from "types/userInsert";

export default function useCreateUser() {
  const queryClient = useQueryClient();

  const createUser = (userInput: UserAdd) => {
    const request: Request = {
      url: `${ENDPOINTS.subscription.users}`,
      config: {
        method: "post",
        data: { data: userInput },
      },
    };
    return client(request);
  };

  return useMutation<Response<null>, Error, UserAdd>(createUser, {
    onSettled: (result, error, variables) => {
      queryClient.invalidateQueries([ENDPOINTS.subscription.main]);
      queryClient.invalidateQueries([
        ENDPOINTS.subscription.main,
        variables.subscriptionId,
      ]);
    },
  });
}
