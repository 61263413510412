import { Tabs, Tab, Box, Grid } from "@mui/material";
import { FC, useState } from "react";
import Page from "../../components/Layout/Page";
import { SettingsWrapper } from "./styles";
import General from "./General/General";
import PasswordSettings from "./Password/Password";
import { useAuth } from "context/auth";
import { Fade } from "components/on-mount-animations";
import { animationConfig } from "config/animation";
function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return <div {...other}>{value === index && <Box p={3}>{children}</Box>}</div>;
}

const Settings: FC = () => {
  const [value, setValue] = useState(0);
  const { admin, useAuthGuard } = useAuth();
  useAuthGuard();

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  if (!admin) return <></>;

  return (
    <Page>
      <div style={{ textAlign: "center" }}>
        <h2>Admin settings</h2>
      </div>
      <SettingsWrapper>
        <div className="settings-card">
          <Grid container spacing={3} className="settings-grid">
            <Grid item xs={12} sm={3} className="tabs-container">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                aria-label="secondary tabs example"
                sx={{
                  ".MuiTabs-flexContainer": {
                    flexDirection: { sm: "column", xs: "row" },
                    overflowX: "auto",
                  },
                  ".MuiTabs-indicator": {
                    display: "none",
                  },
                }}
              >
                <Tab value={0} label="General" className="settings-tab" />
                <Tab value={1} label="Password" className="settings-tab" />
              </Tabs>
            </Grid>
            <Grid item xs={12} sm={9} className="tab-content-container">
              <TabPanel value={value} index={0}>
                <Fade duration={animationConfig.duration}>
                  <General />
                </Fade>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Fade duration={animationConfig.duration}>
                  <PasswordSettings />
                </Fade>
              </TabPanel>
            </Grid>
          </Grid>
        </div>
      </SettingsWrapper>
    </Page>
  );
};

export default Settings;
