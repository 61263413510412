import { useTransition, animated, SpringValue } from "@react-spring/web";
import { FC } from "react";
import { useLocation } from "react-router-dom";

const AnimatedRoutes: FC = ({ children }) => {
  const location = useLocation();
  const transitions = useTransition(location.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    config: { duration: 300 },
  });
  return transitions(
    (
      props: {
        opacity: SpringValue<number>;
      },
      item
    ) => (
      <animated.div style={props} className="animated-container">
        {children}
      </animated.div>
    )
  );
};

export default AnimatedRoutes;
